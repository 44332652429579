const MAX_FILE_SIZE_8_MB = 8388608;
const MAX_FILE_SIZE_100_MB = 104857600;

const MAX_FILE_SIZE_8_MB_TEXT = '8 MB';
const MAX_FILE_SIZE_100_MB_TEXT = '100 MB';

export {
  MAX_FILE_SIZE_8_MB_TEXT,
  MAX_FILE_SIZE_8_MB,
  MAX_FILE_SIZE_100_MB,
  MAX_FILE_SIZE_100_MB_TEXT,
};
