/* eslint-disable */
// helpers
export default async function guardHelper(guard: (...args: any) => boolean): Promise<boolean | { name: string }> {
  return new Promise((res) => {
    let timerId: NodeJS.Timer | null = null;

    const done = guard(res);

    if (!done) {
      timerId = setInterval(() => {
        guard(res, timerId);
      }, 300);
    }
  });
}
