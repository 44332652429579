// constants
import BackendUrls from '@/shared/constants/backend-urls';

function getFileUri(fileUri: File | Blob | string | null, token: string | null = null): string {
  if (fileUri instanceof File || fileUri instanceof Blob) {
    return URL.createObjectURL(fileUri);
  }

  if (!fileUri) {
    return '';
  }

  if (fileUri.match(/^(blob:)?http(s)?:\/\//)) {
    return fileUri;
  }

  return `${BackendUrls.Main}/files?filePath=${fileUri}&access_token=${token}`;
}

export default getFileUri;
