import { ethers } from 'ethers';

// abi
import DropAbi from '@/assets/abi/drop-abi.json';
import ClaimAbi from '@/assets/abi/claim-abi.json';
import AirdropAbi from '@/assets/abi/airdrop-abi.json';
import DropCreationAbi from '@/assets/abi/drop-creation.abi.json';
import PublicMintAbi from '@/assets/abi/public-mint.abi.json';
import WhitelistedMintAbi from '@/assets/abi/whitelisted-mint.abi.json';

// instances
import providers from './providers';

// models
import ContractsBunch from '@/shared/models/connection/contracts-bunch';
import ContractType from '@/shared/models/connection/contract-type.enum';

// constants
import RPC_DATA from '@/shared/constants/rpc-data';
import CONTRACT_ADDRESSES from '@/shared/constants/contract-addresses';

function getContractAddress(chainId: string, type: ContractType): string {
  return CONTRACT_ADDRESSES[chainId][type] || ethers.constants.AddressZero;
}

// eslint-disable-next-line
export function buildContract(abi: any, chainId: string, type: ContractType, address: string | null = null): ethers.Contract {
  return new ethers.Contract(
    address || getContractAddress(chainId, type),
    abi,
    providers.defaultProviders[chainId],
  );
}

function generateContracts(): ContractsBunch {
  const entries = Object.keys(RPC_DATA);

  return entries.reduce((bunch, chainId) => {
    const updatedBunch = { ...bunch };

    updatedBunch[chainId] = {
      [ContractType.Drop]: buildContract(DropAbi, chainId, ContractType.Drop),
      [ContractType.Claim]: buildContract(ClaimAbi, chainId, ContractType.Claim),
      [ContractType.AIRDROP]: buildContract(AirdropAbi, chainId, ContractType.AIRDROP),
      [ContractType.DropCreation]: buildContract(DropCreationAbi, chainId, ContractType.DropCreation),
      [ContractType.PublicMint]: buildContract(PublicMintAbi, chainId, ContractType.PublicMint),
      [ContractType.whitelistedMint]: buildContract(WhitelistedMintAbi, chainId, ContractType.whitelistedMint),
    };

    return updatedBunch;
  }, {} as ContractsBunch);
}

export default generateContracts();
