// constants
import { ethers } from 'ethers';

function maskAddress(
  address: string,
  // eslint-disable-next-line default-param-last
  showAddressNumber = 4,
  onlyFront?: boolean,
  onlyBack?: boolean,
): string {
  if (!ethers.utils.isAddress(address)) {
    return address;
  }

  return `${!onlyBack ? address?.slice(0, showAddressNumber) : ''}...${!onlyFront ? address?.slice(-showAddressNumber) : ''}`;
}

export default maskAddress;
