import { createRouter, createWebHistory } from 'vue-router';

// layouts
import MainLayout from '@/layouts/MainLayout.vue';

// guards
import isAdmin from '@/shared/guards/admin.guard';
import isAuthenticated from '@/shared/guards/auth.guard';
import isCreator from '@/shared/guards/creator.guard';
import isPublished from '@/shared/guards/published.guard';

// models
import RouterNames from '@/shared/models/common/router-names-types.enum';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      component: MainLayout,
      children: [
        {
          path: '',
          name: RouterNames.Home,
          component: () => import('@/pages/Home/HomePage.vue'),
        },
        {
          path: 'drop/:dropId',
          name: RouterNames.Drop,
          component: () => import('@/pages/Drop/DropPage.vue'),
        },
        {
          path: 'coming-soon/:dropId',
          name: RouterNames.DropComingSoon,
          component: () => import('@/pages/DropComingSoon/DropComingSoonPage.vue'),
        },
        {
          path: 'upcoming/:dropId',
          name: RouterNames.OldDrop,
          component: () => import('@/pages/DropItem/DropPage.vue'),
        },
        {
          path: 'profile/:userId',
          component: () => import('@/pages/Profile/ProfilePage.vue'),
          children: [
            {
              path: '',
              name: RouterNames.ProfileContent,
              component: () => import('@/pages/Profile/pages/ContentPage.vue'),
            },
            {
              path: 'audience',
              name: RouterNames.ProfileAudience,
              component: () => import('@/pages/Profile/pages/AudiencePage.vue'),
            },
            {
              path: 'collected-drops',
              name: RouterNames.ProfileCollectedDrops,
              component: () => import('@/pages/Profile/pages/CollectedDropsPage.vue'),
            },
            {
              path: 'drops',
              name: RouterNames.ProfileDrops,
              component: () => import('@/pages/Profile/pages/DropsPage.vue'),
            },
            {
              path: 'featuring-drops',
              name: RouterNames.ProfileFeaturingDrops,
              component: () => import('@/pages/Profile/pages/FeaturingDropsPage.vue'),
            },
          ],
        },
        {
          path: 'drop-list',
          component: () => import('@/pages/DropsList/DropsListPage.vue'),
          redirect: { name: RouterNames.DropListUpcomingDrops },
          children: [
            {
              path: 'drops',
              component: () => import('@/pages/DropsList/components/DropListDropWrapper.vue'),
              children: [
                {
                  path: 'all-drops',
                  name: RouterNames.DropListAllDrops,
                  component: () => import('@/pages/DropsList/pages/DropsListAllDropsPage/DropsListAllDropsPage.vue'),
                },
                {
                  path: 'upcoming-drops',
                  name: RouterNames.DropListUpcomingDrops,
                  component: () => import('@/pages/DropsList/pages/DropsListUpcomingDropsPage/DropsListUpcomingDropsPage.vue'),
                },
                // {
                //   path: 'featured-drops',
                //   name: RouterNames.DropListFeaturedDrops,
                //   component: () => import('@/pages/DropsList/pages/DropsListFeaturedDropsPage/DropsListFeaturedDropsPage.vue'),
                // },
                {
                  path: 'live-drops',
                  name: RouterNames.DropListLiveDrops,
                  component: () => import('@/pages/DropsList/pages/DropsListLiveDropsPage/DropsListLiveDropsPage.vue'),
                },
                // {
                //   path: 'recent-drops',
                //   name: RouterNames.DropListRecentDrops,
                //   component: () => import('@/pages/DropsList/pages/DropsListRecentDropsPage/DropsListRecentDropsPage.vue'),
                // },
              ],
            },
            {
              path: 'creators',
              component: () => import('@/pages/DropsList/components/DropListCreatorWrapper.vue'),
              children: [
                // {
                //   path: 'featured-creators',
                //   name: RouterNames.DropListFeaturedCreators,
                //   component: () => import('@/pages/DropsList/pages/DropsListFeaturedCreatorsPage/DropsListFeaturedCreatorsPage.vue'),
                // },
                {
                  path: 'all-creators',
                  name: RouterNames.DropListAllCreators,
                  component: () => import('@/pages/DropsList/pages/DropsListAllCreatorsPage/DropsListAllCreatorsPage.vue'),
                },
              ],
            },
          ],
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: RouterNames.Home },
        },
      ],
    },
    {
      path: '/profile-settings',
      component: () => import('@/layouts/ProfileSettingsLayout.vue'),
      beforeEnter: [isAuthenticated],
      children: [
        {
          path: '',
          name: RouterNames.ProfileSettingsEditProfile,
          component: () => import('@/pages/ProfileSettings/pages/ProfileSettingsEditProfilePage/ProfileSettingsEditProfilePage.vue'),
        },
        {
          path: 'your-drops',
          beforeEnter: [isCreator],
          children: [
            {
              path: '',
              name: RouterNames.ProfileSettingYourDrops,
              component: () => import('@/pages/ProfileSettings/pages/ProfileSettingsCreatorDropsPage/ProfileSettingsCreatorDropsPage.vue'),
            },
            {
              path: 'drop/:dropId',
              name: RouterNames.ProfileSettingDropDetails,
              component: () => import('@/pages/ProfileSettings/pages/ProfileSettingsReviewAndDeployDrop/ProfileSettingsReviewAndDeployDrop.vue'),
            },
          ],
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: RouterNames.Home },
        },
      ],
    },
    {
      path: '/admin-settings',
      beforeEnter: [isAuthenticated, isAdmin],
      children: [
        {
          path: '',
          component: () => import('@/layouts/AdminDashboardLayout.vue'),
          children: [
            {
              path: '',
              name: RouterNames.AdminDashboardMainDrop,
              component: () => import('@/pages/AdminDashboard/pages/AdminDashboardMainDropPage/AdminDashboardMainDropPage.vue'),
            },
            {
              path: 'featured-creators',
              name: RouterNames.AdminDashboardFeaturedCreators,
              component: () => import('@/pages/AdminDashboard/pages/AdminDashboardFeaturedCreatorsPage/AdminDashboardFeaturedCreatorsPage.vue'),
            },
            {
              path: 'featured-drops',
              name: RouterNames.AdminDashboardFeaturedDrops,
              component: () => import('@/pages/AdminDashboard/pages/AdminDashboardFeaturedDropsPage/AdminDashboardFeaturedDropsPage.vue'),
            },
            {
              path: 'users-settings',
              children: [
                {
                  path: '',
                  name: RouterNames.AdminDashboardUsersSettings,
                  component: () => import('@/pages/AdminDashboard/pages/AdminDashboardUsersSettingsPage/AdminDashboardUsersSettingsPage.vue'),
                },
                {
                  path: 'user/:userId?',
                  name: RouterNames.AdminDashboardUserUpsert,
                  component: () => import('@/pages/AdminDashboard/pages/AdminDashboardUserUpsertPage/AdminDashboardUserUpsertPage.vue'),
                },
              ],
            },
            {
              path: 'list-of-drops',
              name: RouterNames.AdminDashboardListOfDrops,
              component: () => import('@/pages/AdminDashboard/pages/AdminDashboardListOfDropsPage/AdminDashboardListOfDropsPage.vue'),
            },
          ],
        },
        {
          path: 'drop-creation/:dropId?',
          component: () => import('@/layouts/DropCreationLayout.vue'),
          beforeEnter: async (to) => {
            const access = await isPublished(to.params.dropId as string);
            if (!access) {
              return router.push({ name: RouterNames.AdminDashboardListOfDrops });
            }
            return access;
          },
          redirect: { name: RouterNames.DropCreationContentType },
          children: [
            {
              name: RouterNames.DropCreationContentType,
              path: 'content-type',
              component: () => import('@/pages/DropCreation/ContentTypePage/ContentTypePage.vue'),
            },
            {
              name: RouterNames.DropCreationReleaseInfo,
              path: 'release-info',
              component: () => import('@/pages/DropCreation/ReleaseInfoPage/ReleaseInfoPage.vue'),
            },
            {
              name: RouterNames.DropCreationSaleConfiguration,
              path: 'sale-configuration',
              component: () => import('@/pages/DropCreation/SaleConfigurationPage/SaleConfigurationPage.vue'),
            },
            {
              name: RouterNames.DropCreationCollaboratorsAndSplits,
              path: 'collaborators-and-splits',
              component: () => import('@/pages/DropCreation/CollaboratorsAndSplitsPage/CollaboratorsAndSplitsPage.vue'),
            },
            {
              name: RouterNames.DropCreationReviewAndDeploy,
              path: 'review-and-deploy',
              component: () => import('@/pages/DropCreation/ReviewAndDeploy/ReviewAndDeploy.vue'),
            },
            {
              path: ':catchAll(.*)*',
              redirect: { name: RouterNames.DropCreationContentType },
            },
          ],
        },
        {
          path: ':catchAll(.*)*',
          redirect: { name: RouterNames.Home },
        },
      ],
    },
    {
      path: '/twitter-auth',
      name: RouterNames.SuccessTwitterConnection,
      component: () => import('@/pages/ProfileSettings/pages/SuccessTwitterConnectionPage/SuccessTwitterConnectionPage.vue'),
    },
    {
      path: '/discord-auth',
      name: RouterNames.SuccessDiscordConnection,
      component: () => import('@/pages/ProfileSettings/pages/SuccessDiscordConnectionPage/SuccessDiscordConnectionPage.vue'),
    },
    {
      path: '/youtube-auth',
      name: RouterNames.SuccessYoutubeConnection,
      component: () => import('@/pages/ProfileSettings/pages/SuccessYoutubeConnectionPage/SuccessYoutubeConnectionPage.vue'),
    },
    {
      path: '/melon-pass',
      component: () => import('@/layouts/LandingLayout.vue'),
      children: [
        {
          path: '',
          name: RouterNames.MelonPass,
          component: () => import('@/pages/MelonPass/MelonPassPage.vue'),
        },
      ],
    },
    {
      path: '/token',
      component: () => import('@/layouts/LandingLayout.vue'),
      children: [
        {
          path: '',
          name: RouterNames.Token,
          component: () => import('@/pages/Token/TokenPage.vue'),
        },
      ],
    },
    {
      path: '/terms-of-service',
      name: RouterNames.TermsOfService,
      component: () => import('@/pages/TermsOfService/TermsOfServicePage.vue'),
    },
    {
      path: '/privacy-policy',
      name: RouterNames.PrivacyPolicy,
      component: () => import('@/pages/PrivacyPolicy/PrivacyPolicyPage.vue'),
    },
  ],
  scrollBehavior(to, from) {
    return new Promise((resolve) => {
      if (to.hash) {
        resolve({
          top: 0,
          el: to.hash,
          behavior: (from.name === RouterNames.Token || from.name === RouterNames.MelonPass) ? 'auto' : 'smooth',
        });
      }

      resolve({
        top: 0,
      });
    });
  },
});

router.afterEach((to) => {
  // Remove hash from URL after scrolling
  if (to.hash) {
    window.history.replaceState({}, '', to.path);
  }
});

export default router;
