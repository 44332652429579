// models
import LinkData from '@/shared/models/common/link-data';
import RouterNames from '@/shared/models/common/router-names-types.enum';

// constants
import { Whitepaper } from '@/shared/constants/redirect-links';

const ECOSYSTEM_NAV_LINKS: LinkData[] = [
  {
    label: '$MELON Token',
    name: RouterNames.Token,
  },
  {
    label: 'MELON PASS',
    name: RouterNames.MelonPass,
  },
  {
    label: 'FAQ',
    hash: '#faq',
    name: RouterNames.Home,
  },
  {
    label: 'Whitepaper',
    redirect: Whitepaper,
  },
];

const ABOUT_NAV_LINKS: LinkData[] = [
  {
    label: 'Explore',
    name: RouterNames.DropListAllDrops,
  },
  {
    label: '$MELON Token',
    name: RouterNames.Token,
  },
  {
    label: 'MELON PASS',
    name: RouterNames.MelonPass,
  },
  {
    label: 'FAQ',
    hash: '#faq',
    name: RouterNames.Home,
  },
  {
    label: 'Whitepaper',
    redirect: Whitepaper,
  },
];

export { ABOUT_NAV_LINKS, ECOSYSTEM_NAV_LINKS };
