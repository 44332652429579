import { AxiosResponse } from 'axios';

interface ComplexError {
  [key: string]: string[],
}

function unpackResponseError(err: { response: AxiosResponse<ComplexError | string | null>, message: string }): string {
  const error = err.response.data;

  if (error) {
    return typeof error === 'string' ? error : error[Object.keys(error)[0]][0];
  }

  return err.message;
}

export default unpackResponseError;
