// services
import http from '@/instances/http';

// models
import SearchData from '@/shared/models/common/search-data';
import EthToUsdtResp from '@/shared/models/common/eth-to-usdt-resp';

// constants
import BackendUrls from '@/shared/constants/backend-urls';

const CommonApi = {
  async getFileFromUri(fileUri: string): Promise<Blob> {
    const headers = {
      'Access-Control-Allow-Origin': '*',
    };

    const res = await http.get(fileUri, { responseType: 'blob', headers });
    return res.data;
  },
  async subscribeForUpdates(email: string): Promise<void> {
    await http.post('/main', {
      email,
    }, { baseURL: BackendUrls.Email });
  },
  async searchData(searchString: string): Promise<SearchData> {
    const params = { searchString };

    const resp = await http.get('search', { params });
    return resp.data;
  },
  async getEthToUsdt(): Promise<EthToUsdtResp> {
    const resp = await http.get('coins/eth');

    return resp.data;
  },
  async revokeYoutubeToken(accessToken: string): Promise<void> {
    const params = {
      token: accessToken,
    };

    await http.post('revoke', {}, {
      baseURL: 'https://accounts.google.com/o/oauth2/',
      params,
    });
  },
};

export default CommonApi;
