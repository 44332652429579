/* eslint-disable */
import { ethers } from 'ethers';
import { TransactionReceipt } from '@ethersproject/abstract-provider';

// api
import ContractApi from '@/api/contract.api';
import AirdropAbi from '@/assets/abi/airdrop-abi.json';

// instances
import { getSplitClient } from '@/instances/split';

// models
import ContractType from '@/shared/models/connection/contract-type.enum';
import AirdropFunctionTypes from '@/shared/models/airdrop/airdrop-function-types.enum';
import DropCreatingContractFinalData from '@/shared/models/airdrop/final-data.type';
import RecepientsType from '@/shared/models/airdrop/recepients-type';

const AirdropContractApi = {
  async predictContractAddress(owner: string, salt: string): Promise<string> {
    return ContractApi.getContract(ContractType.AIRDROP)
      .predictDropAddress(owner, salt);
  },
  async createDrop(
    web3: ethers.providers.Web3Provider,
    contractAddress: string,
    functionName: AirdropFunctionTypes.CreateDrop,
    chainId: string,
    params: DropCreatingContractFinalData,
  ): Promise<TransactionReceipt> {
    console.log({ contractAddress, functionName, chainId, params, web3 });
    return await ContractApi.requestWithSigner({
      web3,
      functionName,
      params: [params.salt, params.owner, params.initData, params.contracts, params.params],
      contractInfo: {
        type: ContractType.AIRDROP,
        contractAddress,
      },
      chainId,
      waitReceipt: true,
      waitAdditionalField: 'blockNumber',
    }) as TransactionReceipt;
  },
  async createSplit(chainId: number, recipients: RecepientsType, address: string, provider: any) {
    // return getSplitClient(421613, address, provider).createSplit(recipients);


    const client = getSplitClient(chainId, address, provider);
    console.log(client);
    // const some = await getSplitClient(chainId, address, provider).createSplit(recipients);
    // console.log(some);
    return getSplitClient(chainId, address, provider).createSplit(recipients);
  },
  async getSpliInfo(chainId: number, address: string, provider: any, splitAddress: string) {
    const args = {
      splitAddress: splitAddress
    };

    return getSplitClient(chainId, address, provider).getSplitMetadata(args);
  },
  async registerAirdrop(
    web3: ethers.providers.Web3Provider,
    contractAddress: string,
    functionName: AirdropFunctionTypes.AIRDROP,
    chainId: string,
    receivers: string[],
    quantitites: number[],
  ): Promise<TransactionReceipt> {
    return await ContractApi.requestWithSigner({
      web3,
      functionName,
      params: [receivers, quantitites],
      contractInfo: {
        type: ContractType.DropCreation,
        contractAddress,
      },
      chainId,
      waitReceipt: true,
      waitAdditionalField: 'blockNumber',
    }) as TransactionReceipt;
  },
};

export default AirdropContractApi;
