// Transforms model to FormData
// Current possible value types of model props: string, number, boolean, File
// May be extended if needed

// models
import SimpleTypes from '@/shared/models/common/simple-types';

export default function toFormData(
  model: Record<string, SimpleTypes | undefined | null | SimpleTypes[]>,
): FormData {
  const formData = new FormData();

  for (const key of Object.keys(model)) {
    const value = model[key];

    if (value !== undefined && value !== null) {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else if (value instanceof Blob) {
        formData.append(key, value, new Date().toISOString());
      } else if (Array.isArray(value)) {
        value.forEach((part: SimpleTypes) => {
          // TODO: talk with backend that array keys should ends with [] in all cases
          if (part instanceof File) {
            formData.append(key, part, part.name);
          } else if (part instanceof Blob) {
            formData.append(key, part, new Date().toISOString());
          } else {
            formData.append(`${key}[]`, String(part));
          }
        });
      } else {
        formData.append(key, String(value));
      }
    }
  }

  return formData;
}
