import { createApp } from 'vue';
import { createPinia } from 'pinia';
import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueWaveSurfer from 'vue-wave-surfer';

// routing
import router from './router';

// components
import App from './App.vue';

// styles
import './styles/index.scss';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.component('VueDatePicker', VueDatePicker);
app.use(VueWaveSurfer);

app.mount('#app');
