import { storeToRefs } from 'pinia';

// store
import useAuthStore from '@/stores/auth';
import useGlobalLoaderStore from '@/stores/global-loader';

// models
import RouterNames from '@/shared/models/common/router-names-types.enum';

export default function checkAuth(
  res: (data: boolean | { name: string }) => void,
  id: NodeJS.Timer | null = null,
): boolean {
  // common
  const authStore = useAuthStore();
  const globalLoaderStore = useGlobalLoaderStore();

  // refs
  const { isAuthorized } = storeToRefs(authStore);
  const { isGlobalLoading } = storeToRefs(globalLoaderStore);

  if (!isGlobalLoading.value.length) {
    if (id) {
      clearInterval(id);
    }

    if (isAuthorized.value) {
      res(true);
    } else {
      res({ name: RouterNames.Home });
    }

    return true;
  }

  return false;
}
