enum ContractType {
  Drop = 'Drop',
  Claim = 'Claim',
  AIRDROP = 'Airdrop',
  DropCreation = 'DropCreation',
  PublicMint = 'PublicMint',
  whitelistedMint = 'WhiteListedMint',
}

export default ContractType;
