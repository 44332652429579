// models
import CurrencyMap from '@/shared/models/connection/currency-map';
import CryptoCurrency from '@/shared/models/common/crypto-currency.enum';
import ContractType from '@/shared/models/connection/contract-type.enum';

const CurrencyMapToContract: CurrencyMap = {
  [CryptoCurrency.MATIC]: ContractType.Drop,
};

export default CurrencyMapToContract;
