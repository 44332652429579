/* eslint-disable */
import { defineStore, storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

// store
import useGlobalLoaderStore from '@/stores/global-loader';
import useAuthStore from '@/stores/auth';

// api
import UserApi from '@/api/user.api';

// models
import PaginationOptionsRequest from '@/shared/models/common/list/pagination-options-request';
import Audience from '@/shared/models/users/audience';
import ListWithTotalResponse from '@/shared/models/common/list/list-with-total-response';
import User from '@/shared/models/users/user';
import UpdateFullProfileInfo from '@/shared/models/users/update-full-profile-info';
import SocialMediaReq from '@/shared/models/users/social-media-req';

// constants
import { USER_NOT_AUTHORIZED_ERROR } from '@/shared/constants/messages';
import FeatureCreator from '@/shared/models/users/feature-creator';

const useUserStore = defineStore('user', () => {
  // common
  const globalLoader = useGlobalLoaderStore();
  const authStore = useAuthStore();
  const router = useRouter();

  // refs
  const { session } = storeToRefs(authStore);
  const isSuccessSavedDataModal = ref<boolean>(false);

  const featuredCreators = ref<ListWithTotalResponse<FeatureCreator>>({ data: [], total: 0 });

  // helpers
  function toggleSuccessSavedDataModal(toggle: boolean): void {
    isSuccessSavedDataModal.value = toggle;
  }

  // async helpers
  async function sliceUserAudience(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<Audience>> {
    // eslint-disable-next-line no-return-await
    return await UserApi.getUserAudienceById(userId, pagination);
  }

  async function getUserById(userId: string): Promise<User> {
    const clearGlobalLoader = globalLoader.startGlobalLoading();
    try {
      return await UserApi.getUserById(userId);
    } catch (e: any) {
      throw new Error(e.message);
    } finally {
      clearGlobalLoader();
    }
  }

  async function removeTwitter(): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();

    try {
      await UserApi.removeTwitter(session.value.token);
      await authStore.loadUserData();
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Connection',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function removeDiscord(): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();

    try {
      await UserApi.removeDiscord(session.value.token);
      await authStore.loadUserData();
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Connection',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function removeYoutube(): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();

    try {
      await UserApi.removeYoutube(session.value.token);
      await authStore.loadUserData();
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Connection',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function connectTwitter(twitterInfo: SocialMediaReq): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    try {
      await UserApi.connectTwitter(twitterInfo, session.value.token);
    } catch (e: any) {
      throw new Error(e?.response?.data?.message || e.message);
    } finally {
      router.replace({ query: {} });
    }
  }

  async function connectDiscord(twitterInfo: SocialMediaReq): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    try {
      await UserApi.connectDiscord(twitterInfo, session.value.token);
    } catch (e: any) {
      throw new Error(e?.response?.data?.message || e.message);
    } finally {
      router.replace({ query: {} });
    }
  }

  async function connectYoutube(accessToken: string): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    try {
      await UserApi.connectYoutube(accessToken, session.value.token);
    } catch (e: any) {
      throw new Error(e?.response?.data?.message || e.message);
    } finally {
      router.replace({ query: {} });
    }
  }

  async function updateProfileInfo(userInfo: UpdateFullProfileInfo): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();

    try {
      await Promise.all([
        UserApi.updatedUserInfo({ nickname: userInfo.nickname, bio: userInfo.bio, email: userInfo.email }, session.value.token),
        UserApi.updateBannerImage(userInfo.bannerFile, session.value.token),
        UserApi.updateProfileImage(userInfo.avatarFile, session.value.token),
      ]);
      await authStore.loadUserData();
      toggleSuccessSavedDataModal(true);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function getFeaturedUsers(pagination: PaginationOptionsRequest): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    // const clearLoader = globalLoader.startGlobalLoading();

    try {
      featuredCreators.value = await UserApi.getUsersFeaturedList(pagination);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      // clearLoader();
    }
  }

  async function updateFeaturedUsers(featuredUsers: string[]): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    // const clearLoader = globalLoader.startGlobalLoading();

    try {
      await UserApi.updateFeaturedUsers(featuredUsers, session.value.token);
      await getFeaturedUsers({ offset: 0, pageSize: 20 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      // clearLoader();
    }
  }

  async function removeFeaturedUsers(userId: string): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();
    let featuredData: string[] = [];
    featuredCreators.value.data.forEach((el) => {
      if (el.id !== userId) {
        featuredData = [...featuredData, el.id];
      }
    });

    try {
      await UserApi.updateFeaturedUsers(featuredData, session.value.token);
      featuredCreators.value = await UserApi.getUsersFeaturedList({ offset: 0, pageSize: 20 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function addFeaturedUsers(userId: string): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoader.startGlobalLoading();
    let featuredData: string[] = [];
    featuredCreators.value.data.forEach((el) => {
      featuredData = [...featuredData, el.id];
    });
    featuredData.push(userId);

    try {
      await UserApi.updateFeaturedUsers(featuredData, session.value.token);
      await getFeaturedUsers({ offset: 0, pageSize: 20 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  return {
    isSuccessSavedDataModal,
    featuredCreators,
    getFeaturedUsers,
    getUserById,
    sliceUserAudience,
    updateProfileInfo,
    updateFeaturedUsers,
    addFeaturedUsers,
    connectTwitter,
    connectYoutube,
    connectDiscord,
    removeDiscord,
    removeTwitter,
    removeYoutube,
    removeFeaturedUsers,
    toggleSuccessSavedDataModal,
  };
});

export default useUserStore;
