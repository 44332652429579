// models
import ChainContractAddresses from '@/shared/models/connection/chain-contract-addresses';
import ContractType from '@/shared/models/connection/contract-type.enum';

const CONTRACT_ADDRESSES: ChainContractAddresses = {
  [process.env.VUE_APP_NETWORK_CHAIN_ID as string]: {
    [ContractType.Claim]: process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS,
    [ContractType.AIRDROP]: process.env.VUE_APP_CONTRACT_AIRDROP_ADDRESS,
    [ContractType.PublicMint]: process.env.VUE_APP_CONTRACT_PUBLIC_MINT_ADDRESS,
    [ContractType.whitelistedMint]: process.env.VUE_APP_CONTRACT_WHITELISTED_MINT_ADDRESS,
  },
  [process.env.VUE_APP_NETWORK_CHAIN_ID_SECOND as string]: {
  },
};

export default CONTRACT_ADDRESSES;
