import { ref, customRef, type Ref } from 'vue';
import debounce from 'lodash/debounce';

function useDebouncedRef<T>(initialValue: T, delay: number): Ref<T> {
  const state = ref(initialValue);

  return customRef((track, trigger) => ({
    get() {
      track();
      return state.value as T;
    },
    set: debounce(
      (value: T) => {
        // eslint-disable-next-line
        state.value = value as any;
        trigger();
      },
      delay,
    ),
  }));
}

export default useDebouncedRef;
