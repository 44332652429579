// models
import ContractType from '@/shared/models/connection/contract-type.enum';
import TokenContractMapType from '@/shared/models/connection/token-contract-map-type';
import SpecificCurrencyToken from '@/shared/models/connection/specific-currency-token.enum';

const TokenContractMap: TokenContractMapType = {
  [SpecificCurrencyToken.ETH]: ContractType.Drop,
};

export default TokenContractMap;
