import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const useGlobalLoaderStore = defineStore('global-loader', () => {
  // ref
  const isGlobalLoading = ref<number[]>([]);

  // computed
  const isLoading = computed<boolean>(() => isGlobalLoading.value.length >= 0);

  // async helpers
  function startGlobalLoading(): () => void {
    const globalLoadingId = uuidv4();

    isGlobalLoading.value.push(globalLoadingId);

    return () => {
      isGlobalLoading.value = isGlobalLoading.value.filter((el) => el !== globalLoadingId);
    };
  }

  return {
    isGlobalLoading,
    isLoading,
    startGlobalLoading,
  };
});

export default useGlobalLoaderStore;
