import jwtDecode from 'jwt-decode';

// models
import TokenBundleWithExp from '@/shared/models/connection/token-bundle-with-exp';
import TokenBundle from '@/shared/models/connection/token-bundle';

export default function parseTokenBundle(tokenBundle: TokenBundle): TokenBundleWithExp {
  const { exp }: { exp: number } = jwtDecode(tokenBundle.token as string);
  const expDate = new Date(exp * 1000).toISOString();

  return {
    ...tokenBundle,
    expDate,
  };
}
