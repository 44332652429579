// models
import ConnectedUserInfo from '@/shared/models/connection/connected-user-info';

abstract class ProviderWrapper {
  // eslint-disable-next-line
  abstract provider: any;

  // eslint-disable-next-line
  abstract getWeb3Provider(type: string): any;

  abstract getNearestWalletData(): ConnectedUserInfo | null;

  abstract connect(): Promise<ConnectedUserInfo>;

  abstract autoConnect(type: string): Promise<void>;

  abstract disconnect(exceptType?: string): Promise<void>;

  abstract getChainId(type: string): string;

  abstract checkIsCorrectChain(type: string): boolean;

  abstract getChainCurrency(type: string): string | null;

  abstract checkConnection(type?: string, address?: string): boolean;
}

export default ProviderWrapper;
