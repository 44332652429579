import { ethers } from 'ethers';

// models
import { ADDRESS_CHECKSUM_ERROR } from '@/shared/constants/messages';
import LocalStorageSessionData from '@/shared/models/connection/local-storage-session-data';

// constants
const LAST_USER = 'last_user';

const LocalStorageService = {
  getSession(address?: string | null): LocalStorageSessionData | null {
    if (address) {
      try {
        const sessionData = window.localStorage.getItem(ethers.utils.getAddress(address));
        return sessionData ? JSON.parse(sessionData) : null;
      } catch (e) {
        throw new Error(ADDRESS_CHECKSUM_ERROR);
      }
    }

    return null;
  },
  getLastUser(): string | null {
    return window.localStorage.getItem(LAST_USER);
  },
  setSession(address: string, data?: LocalStorageSessionData): void {
    let normalizedAddress: string;
    try {
      normalizedAddress = ethers.utils.getAddress(address);
    } catch (e) {
      throw new Error(ADDRESS_CHECKSUM_ERROR);
    }

    if (data) {
      const sessionDataStr = JSON.stringify(data);
      window.localStorage.setItem(normalizedAddress, sessionDataStr);
    }

    window.localStorage.setItem(LAST_USER, normalizedAddress);
  },
  clearSession(address: string): void {
    try {
      window.localStorage.removeItem(ethers.utils.getAddress(address));
    } catch (e) {
      throw new Error(ADDRESS_CHECKSUM_ERROR);
    }
  },
  clearLastUser(): void {
    window.localStorage.removeItem(LAST_USER);
  },
};

export default LocalStorageService;
