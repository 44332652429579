/* eslint-disable */
import { Ref, ref, watch } from 'vue';

// personal interface
interface UseCheckWindowClick {
  isActive: Ref<boolean>;
  elementRef: Ref<HTMLElement | null>;
  setIsActive: (value: boolean) => void;
}

function useCheckWindowClick(): UseCheckWindowClick {
  const elementRef = ref<HTMLElement | null>(null);
  const isActive = ref<boolean>(false);

  function checkWindowClick(event: any): void {
    const path = event.path || (event.composedPath && event.composedPath());

    if (path) {
      if (elementRef.value && path.includes(elementRef.value)) {
        setIsActive(path.includes(elementRef.value));
        return;
      }
    }

    setIsActive(false);
  }

  function setIsActive(toggle: boolean): void {
    isActive.value = toggle;
  }

  // watchers
  watch([isActive], () => {
    if (isActive.value) {
      window.addEventListener('touchstart', checkWindowClick);
      window.addEventListener('click', checkWindowClick);

      return;
    }

    window.removeEventListener('touchstart', checkWindowClick);
    window.removeEventListener('click', checkWindowClick);
  });

  return {
    isActive,
    elementRef,
    setIsActive,
  };
}

export default useCheckWindowClick;
