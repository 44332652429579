// instances
import http from '@/instances/http';

// helpers
import unpackResponseError from '@/shared/helpers/unpack-response-error';

// models
import TokenBundle from '@/shared/models/connection/token-bundle';

// constants
import BackendUrls from '@/shared/constants/backend-urls';
import DefaultAuthData from '@/shared/constants/default-auth-data';

const AuthApi = {
  async getNonce(publicAddress: string): Promise<string> {
    try {
      const res = await http.get(
        '/nonce',
        { baseURL: BackendUrls.Auth, params: { publicAddress } },
      );
      return res.data;
      // eslint-disable-next-line
    } catch (e: any) {
      throw new Error(unpackResponseError(e));
    }
  },
  async authUser(publicAddress: string, signature: string): Promise<TokenBundle> {
    const res = await http.post(
      '/connect/token',
      new URLSearchParams({
        ...DefaultAuthData,
        grant_type: 'public_address',
        public_address: publicAddress,
        signature,
      }),
      { baseURL: BackendUrls.Auth },
    );

    return {
      token: res.data.access_token,
      refreshToken: res.data.refresh_token,
    };
  },
  async refreshToken(refreshToken: string): Promise<TokenBundle> {
    const res = await http.post(
      '/connect/token',
      new URLSearchParams({
        ...DefaultAuthData,
        grant_type: 'refresh_token',
        refresh_token: refreshToken as string,
      }),
      { baseURL: BackendUrls.Auth },
    );

    return {
      token: res.data.access_token,
      refreshToken: res.data.refresh_token,
    };
  },
};

export default AuthApi;
