const AudioOptions = { // https://wavesurfer-js.org/docs/options.html
  barGap: 4,
  barWidth: 5,
  barMinHeight: 5,
  barHeight: 0.25,
  barRadius: 4,
  height: 48,
  cursorWidth: 0,
  hideScrollbar: true,
  responsive: true,
  normalize: true,
};

// eslint-disable-next-line
export function getAudioOptions(): any {
  return {
    ...AudioOptions,
    cursorColor: '#1A1B22',
    progressColor: '#1A1B22',
    waveColor: '#A1E6D5',
  };
}

export default AudioOptions;
