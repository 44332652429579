// constants
import RPC_DATA from '@/shared/constants/rpc-data';
import { MAX_FILE_SIZE_8_MB_TEXT } from '@/shared/constants/max-file-size';

// contract interaction
export const CONTRACT_ADDRESS_ERROR = 'Contract address for this transaction is not loaded. Please reload the page and try again';
export const NOT_ENOUGH_MONEY_ERROR = (currency = 'Token'): string => `Not enough ${currency}.`;
export const CONTRACT_RECEIPT_ERROR = (hash: string): string => `The contract execution was not successful, check your transaction ${hash}!`;
export const NETWORK_ERROR = (networkName?: string): string => (
  `You're using wrong network. <br>
  Please switch to another network (${networkName || (Object.values(RPC_DATA).map((el) => el.name.toUpperCase())).join(', ')}) and try again.`
);

// mint
export const NOT_IN_WHITE_LIST = 'Sorry, you are not eligible to mint in this phase.';

// connection
export const CONTRACT_ADDRESS_ON_ANOTHER_CHAIN_ERROR = (ids: string[]): string => (
  `Contract address for this chain is not loaded. Please switch to another network with loaded contract address
  (${(ids.map((id) => RPC_DATA[id].name)).join(', ')}) and try again.`
);
export const CONTRACT_TYPE_NOT_FOUND_ERROR = (type: string): string => `Contract type not found: ${type}`;
export const EMPTY_CONTRACT_TYPE_ERROR = 'Contract type is not defined';
export const TOKEN_CONTRACT_NOT_FOUND_ERROR = (currency: string): string => `${currency} contract address hasn't been found on this chain.`;
export const NONCE_MESSAGE = (nonce: string): string => `I am signing my one-time nonce: ${nonce}`;
export const NOT_INSTALLED_ERROR = (connectionType: ConnectionType): string => `Please install ${connectionType} to use this app.`;
export const WALLET_CONNECTION_CANCELED = 'Connection canceled.';
export const SIGNING_AUTH_CANCELED = 'Authorization signing canceled.';
export const WALLET_CONNECTION_PENDING_ERROR = 'You have the connection request as pending. Please Approve or Cancel it and try again.';
export const ADDRESS_CHECKSUM_ERROR = 'Got invalid address from provider.';
export const USER_NOT_AUTHORIZED_ERROR = 'User is not authorized.';
export const WC_KILL_SESSION_ERROR = 'Unable to kill previous session on wallet connect.';
export const OAUTH_CONNECTION_ERROR = 'The resource owner or authorization server denied the request';
export const TOKEN_EXPIRED_ERROR = 'Token has been expired.';

// validation
export const REQUIRED_ERROR = (name = 'Field'): string => `${name} is required`;
export const MinEightSymbolsError = 'Your password needs at least 8 characters long';
export const OneLowerCaseError = 'Your password needs at least 1 small letter.';
export const OneUpperCaseError = 'Your password needs at least 1 capital letter.';
export const OneNumberError = 'Your password needs a at least 1 number.';
export const OneSpecialSymbolError = 'Your password needs at least 1 special symbol';
export const CONFIRM_PASSWORD_ERROR = 'Password mismatch';
export const AGE_ERROR = 'Must be 18 or older';
export const TYPE_ERROR = (type: string, isVowel = false): string => (
  `Provided value is not a${isVowel ? 'n' : ''} ${type}`
);
export const MIN_ERROR = (expected: string | number, equal = false): string => (
  `Value should be greater ${equal ? 'or equal' : 'than'} ${expected}`
);
export const MAX_ERROR = (expected: string | number, equal = false): string => (
  `Value should be less ${equal ? 'or equal' : 'than'} ${expected}`
);
export const MAX_LENGTH_ERROR = (expected: string | number): string => (
  `Text should be shorter than ${expected} symbols`
);

export const WRONG_FILE_TYPE = (fileTypes: string[]): string => (
  `The file must be of the following ${fileTypes.length > 1 ? 'types' : 'type'} ${fileTypes.join(', ')}.`
);

export const MAX_PERCENTAGE_TOTAL_ERROR = (text: string | number = '0', maxPercentage: string | number = '100'): string => (
  `Should be ${maxPercentage}%. You have ${text}%`
);

export const DUPLICATED_FIELD_VALUE = (text = 'Field'): string => `${text} can't be duplicated`;

export const NO_CREATOR_ERROR = 'Should be one Creator';

export const INVALID_ERROR = (text = 'Field'): string => `Invalid ${text}`;
export const NO_SPACES_ERROR = 'String should contain no spaces';

export const MAX_FILE_SIZE_ERROR = (fileSize: string = MAX_FILE_SIZE_8_MB_TEXT): string => (
  `Selected file size is too big. File size should be less than ${fileSize}.`
);
export const MAX_FILE_COUNT_ERROR = (amount: number): string => (
  `You selected to many files. Max total amount of files is ${amount}.`
);
// user message
export const SUCCESSFULLY_SUBSCRIBE = 'Thank you! Stay tuned for updates!';
export const USER_UNREGISTERED = 'Unregistered user address';
