enum DropStatuses {
  NotLaunched = 'NotLaunched',
  Minted = 'Minted',
  MintEnded = 'MintEnded',
  Created = 'Created',
  SentToPublish = 'SentToPublish',
  Published = 'Published',
}

export default DropStatuses;
