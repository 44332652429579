import axios from 'axios';

// constants
import BackendUrls from '@/shared/constants/backend-urls';

const http = axios.create({
  baseURL: BackendUrls.Main,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      // eslint-disable-next-line
    } as any,
  },
});

export default http;
