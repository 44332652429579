function removeCoinBaseConnectionFromLocalStorage(): void {
  const keysForRemove = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i)?.includes('-walletlink:')) {
      keysForRemove.push(localStorage.key(i));
    }
  }

  keysForRemove.forEach((el) => {
    localStorage.removeItem(el || '');
  });
}

export default removeCoinBaseConnectionFromLocalStorage;
