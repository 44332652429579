// instances
import http from '@/instances/http';

// helpers
import toFormData from '@/shared/helpers/to-form-data';

// models
import PaginationOptionsRequest from '@/shared/models/common/list/pagination-options-request';
import ListWithTotalResponse from '@/shared/models/common/list/list-with-total-response';
import SetMainDropData from '@/shared/models/drops/set-main-drop-data';
import DraftDropItem from '@/shared/models/drops/draft-drop-item';
import FiltersDropItems from '@/shared/models/admin/filters-drop-items';
import DropItem from '@/shared/models/drops/drop-item';
import FiltersDraftDropItems from '@/shared/models/admin/filters-draft-drop-items';
import ChainTypes from '@/shared/models/common/chain-types.enum';
import DropCreationReleaseInfoType from '@/shared/models/drops/drop-creation-release-info-type';
import DropCollaboratorsAndSplits from '@/shared/models/drops/drop-collaborators-and-splits';
import DropItemAdminDetails from '@/shared/models/drops/drop-item-admin-details';
import SocialMediaType from '@/shared/models/common/social-media-types.enum';
import DropCreationSaleConfiguration from '@/shared/models/drops/drop-creation-sale-configuration-type';
import DropCreationCompletedSteps from '@/shared/models/drops/drop-creation-completed-step';
import DropCreatingContractFinalData from '@/shared/models/airdrop/final-data.type';

const AdminDropApi = {
  async getPublishedDrops(
    pagination: PaginationOptionsRequest,
    filters: FiltersDropItems | null,
    token: string,
  ): Promise<ListWithTotalResponse<DropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination, ...filters };
    const resp = await http.get('drops/published', { headers, params });

    return resp.data;
  },
  async updateFeaturedDrops(featuredDrops: string[], token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.post('/drops/featured', featuredDrops, { headers });
  },
  async getFeaturedDropList(
    filters: FiltersDropItems | null,
    pagination: PaginationOptionsRequest,
    token: string,
  ): Promise<ListWithTotalResponse<DropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination, ...filters };
    const resp = await http.get('/drops/featured-candidates', { headers, params });

    return resp.data;
  },
  async approveCreationStepById(
    dropId: string,
    approveStep: DropCreationCompletedSteps[],
    token: string,
  ): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`/drops/${dropId}/creation-steps/approvement`, { dropCreationSteps: approveStep }, { headers });
  },
  async removeMainDrop(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.delete('drops/main', { headers });
  },
  async setMainDrop(data: SetMainDropData, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...data };

    await http.post('drops/main', {}, { headers, params });
  },
  async getDraftDrops(
    pagination: PaginationOptionsRequest,
    filters: FiltersDraftDropItems,
    token: string,
  ): Promise<ListWithTotalResponse<DraftDropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination, ...filters };

    const resp = await http.get('drops/draft', { headers, params });

    return resp.data;
  },
  async getDropAdminDetailsById(id: string | number, token: string): Promise<DropItemAdminDetails> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const resp = await http.get(`drops/${id}/admin-details`, { headers });

    return resp.data;
  },
  async getDropAdminDetailsForDeployment(id: string | number, token: string): Promise<DropCreatingContractFinalData> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const resp = await http.get(`drops/${id}/deployment/data`, { headers });

    return resp.data;
  },
  async createDrop(chain: ChainTypes, token: string): Promise<string> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { chain };
    const resp = await http.post('drops', {}, { headers, params });

    return resp.data;
  },
  async setSplitRoyaltiesContract(dropId: string, splitContractAddress: string, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${dropId}/splits-contract`, { contractAddress: splitContractAddress }, { headers });
  },
  async setDropTypeById(dropType: SocialMediaType, id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${id}/type`, { dropType }, { headers });
  },
  async setDropDetailsById(data: DropCreationReleaseInfoType, id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${id}/details`, toFormData(data), { headers });
  },
  async setDropRoyaltiesConfigurationById(data: DropCollaboratorsAndSplits[], id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${id}/royalties-configuration`, {
      royaltySplits: data,
    }, { headers });
  },
  async deployDropById(id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${id}/deployment`, {}, { headers });
  },
  async setSaleConfigurationById(data: DropCreationSaleConfiguration, id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`drops/${id}/sale-configuration`, data, { headers });
  },
  async setGoldenFrameById(data: {file: File | null}, id: string | number, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log(data, 'REQUEST');

    await http.put(`drops/${id}/golden-frame`, toFormData({ File: data.file }), { headers });
  },
  async changeDropComingSoonStatus(dropId: string, enable: boolean, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { enable };

    await http.put(`drops/${dropId}/coming-soon-status`, {}, { headers, params });
  },
};

export default AdminDropApi;
