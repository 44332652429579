/* eslint-disable */
import http from '@/instances/http';
import { Bytes, ethers } from 'ethers';

// api
import ContractApi from '@/api/contract.api';

// models
import Drop from '@/shared/models/drops/drop';
import PaginationOptionsRequest from '@/shared/models/common/list/pagination-options-request';
import ListWithTotalResponse from '@/shared/models/common/list/list-with-total-response';
import DropItem from '@/shared/models/drops/drop-item';
import Ownership from '@/shared/models/drops/ownership';
import DropContractMint from '@/shared/models/drops/drop-contract-mint-types.enum';
import ContractType from '@/shared/models/connection/contract-type.enum';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import ChainTypes from '@/shared/models/common/chain-types.enum';
import DropCreators from '@/shared/models/drops/drop-creators';
import OwnershipFilters from '@/shared/models/drops/ownership-filters';
import DropListFilters from '@/shared/constants/drop-list-filters';

const DropApi = {
  async getDropById(dropId: string, chain?: ChainTypes, token?: string): Promise<Drop> {
    const params = { chain };
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      }
    }

    const resp = await http.get(`/drops/${dropId}`, { params, headers });
    return resp.data;
  },
  async getDropCreatorsById(searchQuery: string, dropId: string, token: string): Promise<DropCreators[]> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { searchQuery };

    const resp = await http.get(`/drops/${dropId}/creators`, { headers, params });
    return resp.data;
  },
  async shareDropWithCreatorById(publicAddress: string, dropId: string, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put(`/drops/${dropId}/deployment/share`, { publicAddress }, { headers });
  },
  async getMainDrop(): Promise<DropItem> {
    const resp = await http.get('/drops/main');
    return resp.data;
  },
  async getDropPhaseProofs(dropId: string, phaseId: number, token: string): Promise<string[]> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get(`/drops/${dropId}/phases/${phaseId}/proof`, { headers });
    // return res.data.map((proof: string) => `0x${proof}`);
    return res.data || null;
  },
  async getDropMintById(dropId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<any>> {
    const params = { ...pagination };

    const resp = await http.get(`/drops/${dropId}/mints`, { params });
    return resp.data;
  },
  async getUpcomingDropList(pagination: PaginationOptionsRequest, filters: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops/upcoming', { params });
    return resp.data;
  },
  async getLiveDropList(pagination: PaginationOptionsRequest, filters: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops/live', { params });
    return resp.data;
  },
  async getRecentDropList(pagination: PaginationOptionsRequest, filters: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops/recent', { params });
    return resp.data;
  },
  async getUserDraftDrops(pagination: PaginationOptionsRequest, token: string): Promise<ListWithTotalResponse<DropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination };

    const resp = await http.get('users/current/drops/draft', { headers, params });
    return resp.data;
  },
  async getUserReadyToPublishDrops(pagination: PaginationOptionsRequest, token: string): Promise<ListWithTotalResponse<DropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination };

    const resp = await http.get('users/current/drops/ready-to-publish', { headers, params });
    return resp.data;
  },
  async getUserPublishedDropsByWalletAddress(
    pagination: PaginationOptionsRequest,
    walletAddress: string, token: string,
  ): Promise<ListWithTotalResponse<DropItem>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { ...pagination };

    const resp = await http.get(`users/${walletAddress}/drops`, { headers, params });
    return resp.data;
  },
  async getDropOwnershipsById(
    dropId: string,
    pagination : PaginationOptionsRequest,
    filters: OwnershipFilters,
  ): Promise<ListWithTotalResponse<Ownership>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get(`/drops/${dropId}/ownerships`, { params });
    return resp.data;
  },
  async getAllDropsList(pagination: PaginationOptionsRequest, filters?: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops', { params });
    console.log(resp.data);
    return resp.data;
  },
  async getSelectedFeaturedDropList(pagination: PaginationOptionsRequest, filters?: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops/featured', { params });

    return resp.data;
  },
  async getSelectedLiveDropList(pagination: PaginationOptionsRequest, filters?: DropListFilters): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/drops/live', { params });

    return resp.data;
  },
  async notifyMint(value: TransactionReceipt, contractAddress: string, chain: ChainTypes, token: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const resp = await http.post(
      `/transactions/notification/launchpad-transfer?contractAddress=${contractAddress}&blockNum=${value.blockNumber}&chain=${chain}`,
      {},
      { headers }
    );

    return resp.data;
  },
  async notifyDropCreated(value: TransactionReceipt, chain: ChainTypes, token: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const resp = await http.post(
      `/transactions/notification/drop-created?blockNum=${value.blockNumber}&chain=${chain}`,
      {},
      { headers }
    );

    return resp.data;
  },
  async dropMintReq(
    web3: ethers.providers.Web3Provider,
    contractAddress: string,
    functionName: DropContractMint,
    address: string,
    phaseIndex: number,
    quantity: number,
    price: string,
    chainId: string,
    proofs?: string[],
    v2?: boolean,
    publicMint?: boolean,
    maxQuantity?: number,
  ): Promise<TransactionReceipt> {
    if (v2) {
      return await ContractApi.requestWithSigner({
        web3,
        functionName,
        params: publicMint
          ? [contractAddress, phaseIndex, address, quantity]
          : [contractAddress, phaseIndex, address, quantity, maxQuantity, proofs],
        contractInfo: {
          type: publicMint ? ContractType.PublicMint : ContractType.whitelistedMint,
        },
        chainId,
        waitReceipt: true,
        waitAdditionalField: 'blockNumber',
        price,
      }) as TransactionReceipt;
    } else {
      return await ContractApi.requestWithSigner({
        web3,
        functionName,
        params: functionName === DropContractMint.PUBLIC_MINT
          ? [phaseIndex, address, quantity]
          : [phaseIndex, address, proofs, quantity],
        contractInfo: {
          type: ContractType.Drop,
          contractAddress,
        },
        chainId,
        waitReceipt: true,
        waitAdditionalField: 'blockNumber',
        price,
      }) as TransactionReceipt;
    }
  },
};

export default DropApi;
