export const PrivateInvestorSignUp = 'https://docs.google.com/forms/d/e/1FAIpQLSdm9WZrY754GuQyttwsAp26bMa23nZVaPF1iYtG1tBk-ASQdQ/viewform';
export const CreatorSignUp = 'https://airtable.com/shr6QGiW8RV9qh05b';
export const Whitepaper = 'https://melonooo.gitbook.io/melonpaper/melon-ecosystem/usdmelon-token';

export const MelonCoingeckoUrl = 'https://www.coingecko.com/en/coins/melon';

export const WhatIsMelon = 'https://melonooo.gitbook.io/melonpaper/introduction/what-is-melon';

export const MelonPaperPreSaleIdo = 'https://melonooo.gitbook.io/melonpaper/usdmelon-token-launch/usdmelon-pre-sale-ido';

// eslint-disable-next-line max-len
export const UniswapWethToMelonUrl = 'https://app.uniswap.org/#/swap?inputCurrency=0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2&outputCurrency=0x37DbA54Fdc402AFF647CE06c66972F5D662c326d';

export const RegisterForAllowlist = 'https://www.alphabot.app/melon-pass';

export const OpenSea = process.env.VUE_APP_OPENSEA_URI;

export const TransactionLink = process.env.VUE_APP_TX_ID;

export const LearnJuicePotions = 'https://melonooo.gitbook.io/melonpaper/usdmelon-token-launch/juice-campaign-airdrop';

export const AirDropMelonPassHoldersLink = 'https://melonooo.gitbook.io/melonpaper/usdmelon-token-launch/airdrop-for-melon-pass-holders';
