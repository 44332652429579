import { ethers } from 'ethers';
import { TransactionReceipt, TransactionResponse } from '@ethersproject/abstract-provider';

// instances
import Contracts from '@/instances/contracts';

// models
import ContractType from '@/shared/models/connection/contract-type.enum';
import OverrideData from '@/shared/models/connection/override-data';
import RequestWithSignerParams from '@/shared/models/connection/request-with-signer-params';
import ApproveTokenRequest from '@/shared/models/connection/approve-token-request';
import TokenContractInfo from '@/shared/models/connection/token-contract-info';
import ContractAddressInfo from '@/shared/models/connection/contract-address-info';
import CryptoCurrency from '@/shared/models/common/crypto-currency.enum';
import SpecificCurrencyToken from '@/shared/models/connection/specific-currency-token.enum';

// constants
import MAIN_CHAIN from '@/shared/constants/main-chain';
import {
  CONTRACT_ADDRESS_ERROR, CONTRACT_ADDRESS_ON_ANOTHER_CHAIN_ERROR,
  CONTRACT_RECEIPT_ERROR, CONTRACT_TYPE_NOT_FOUND_ERROR, EMPTY_CONTRACT_TYPE_ERROR,
  NOT_ENOUGH_MONEY_ERROR, TOKEN_CONTRACT_NOT_FOUND_ERROR,
} from '@/shared/constants/messages';
import CurrencyMapToContract from '@/shared/constants/currency-map-to-contract';
import TokenContractMap from '@/shared/models/connection/token-contract-map';

const ContractApi = {
  // common helpers
  async setupContractAddresses(): Promise<void> {
    // Contracts[MAIN_CHAIN][ContractType.Drop] = Contracts[MAIN_CHAIN][ContractType.Drop].attach(
    //   await this.requestContractAddress(),
    // );
  },
  // async requestContractAddress(): Promise<string> {
  //   const res = await http.get('/transactions/contracts/');
  //   return res.data;
  // },
  // common transactions without signer
  async getBalance(currency: CryptoCurrency, address: string): Promise<string> {
    const res = await this.getContract(CurrencyMapToContract[currency]).balanceOf(address);
    return res.toString();
  },

  getContract(
    type: ContractType | undefined | null,
    chainId: string = MAIN_CHAIN,
    defineRequired = true,
  ): ethers.Contract {
    if (!type) {
      throw new Error(EMPTY_CONTRACT_TYPE_ERROR);
    }

    const contract = Contracts[chainId][type];

    if (!contract) {
      throw new Error(CONTRACT_TYPE_NOT_FOUND_ERROR(type));
    }

    if (defineRequired && contract.address === ethers.constants.AddressZero) {
      const definedIds = this.findChainIdsWithContract(type);
      throw new Error(
        definedIds.length
          ? CONTRACT_ADDRESS_ON_ANOTHER_CHAIN_ERROR(definedIds)
          : CONTRACT_ADDRESS_ERROR,
      );
    }

    return contract;
  },

  getTokenContractInstance(
    currency: SpecificCurrencyToken,
    chainId: string,
    tokenContractAddress?: string,
  ): ethers.Contract {
    const contract = TokenContractMap[currency];

    let contractInstance: ethers.Contract;

    try {
      contractInstance = this.getContract(contract, chainId, !tokenContractAddress);
    } catch (e) {
      throw new Error(TOKEN_CONTRACT_NOT_FOUND_ERROR(currency));
    }

    if (tokenContractAddress) {
      contractInstance = contractInstance.attach(tokenContractAddress);
    }

    return contractInstance;
  },

  findChainIdsWithContract(type: ContractType): string[] {
    return Object
      .entries(Contracts)
      // eslint-disable-next-line
      .filter(([_, contracts]) => contracts[type].address !== ethers.constants.AddressZero)
      .map(([id]) => id);
  },

  // common transactions without signer
  async getTokenBalance(
    tokenInfo: TokenContractInfo,
    chainId: string,
    userAddress: string,
  ): Promise<string | null> {
    const contractInstance = this.getTokenContractInstance(tokenInfo.token, chainId, tokenInfo.tokenAddress);
    const res = await contractInstance.balanceOf(userAddress);
    return res.toString();
  },
  async getApprovedTokenAmount(
    tokenInfo: TokenContractInfo,
    spenderInfo: ContractAddressInfo,
    chainId: string,
    userAddress: string,
  ): Promise<string | null> {
    const spenderAddress = spenderInfo.contractAddress || this.getContract(spenderInfo.type, chainId).address;

    const contractInstance = this.getTokenContractInstance(tokenInfo.token, chainId, tokenInfo.tokenAddress);

    const res = await contractInstance.allowance(userAddress, spenderAddress);
    return res.toString();
  },

  // transaction with signer helpers
  async approveSpecificToken(
    {
      web3,
      tokenInfo,
      spenderInfo,
      chainId,
      price,
      approveBalance,
    }: ApproveTokenRequest,
  ): Promise<void> {
    const signer = web3.getSigner();
    const spenderAddress = spenderInfo.contractAddress || this.getContract(spenderInfo.type, chainId).address;

    const userAddress = await signer.getAddress();
    const priceBN = ethers.BigNumber.from(price);

    const balance = this.getTokenBalance(tokenInfo, chainId, userAddress);

    if (priceBN.gt(ethers.BigNumber.from(balance))) {
      throw new Error(NOT_ENOUGH_MONEY_ERROR(tokenInfo.token));
    }

    const approvedAmount = await this.getApprovedTokenAmount(tokenInfo, spenderInfo, chainId, userAddress);

    // if user already approved usage of this custom currency, we haven't to request the approval again
    if (priceBN.gt(ethers.BigNumber.from(approvedAmount))) {
      const tokenContractWithSigner = this
        .getTokenContractInstance(tokenInfo.token, chainId, tokenInfo.tokenAddress)
        .connect(signer);

      const approveTx = await tokenContractWithSigner.approve(spenderAddress, approveBalance ? balance : price);
      await approveTx.wait();
    }
  },

  async requestWithSigner(options: RequestWithSignerParams): Promise<TransactionReceipt | TransactionResponse> {
    const {
      web3,
      contractInfo,
      chainId,
      tokenInfo,
      price,
      approveBalance,
      functionName,
      params,
      waitReceipt,
      waitAdditionalField,
    } = options;

    // creating contract instance
    let contractInstance = this.getContract(contractInfo.type, chainId, !contractInfo.contractAddress);

    if (contractInfo.contractAddress) {
      contractInstance = contractInstance.attach(contractInfo.contractAddress);
    }

    const contractWithSigner = contractInstance.connect(web3.getSigner());

    // define additional params for transaction
    const override: OverrideData = {};

    // - if transaction needs payment, we need estimate manually how much it will cost
    // - or if transaction requires other currency for payment, we should approve that transaction on that token's contract
    if (price) {
      if (tokenInfo) {
        await this.approveSpecificToken({
          web3,
          tokenInfo,
          chainId,
          spenderInfo: contractInfo,
          approveBalance,
          price,
        });
      } else {
        override.value = price as string;

        const estimatedGas = await contractWithSigner.estimateGas[functionName](
          ...(params || []),
          override,
        );
        override.gasLimit = Math.round(+estimatedGas);
      }
    }

    const tx: TransactionResponse = await contractWithSigner[functionName](...(params || []), override);

    if (waitReceipt) {
      await tx.wait();

      if (waitAdditionalField) {
        return this.waitForReceipt(web3, tx.hash, waitAdditionalField);
      }
    }

    return tx;
  },

  // some time we should wait for the end of the transaction
  async waitForReceipt(
    web3: ethers.providers.Web3Provider,
    hash: string,
    field?: keyof TransactionReceipt, // some time we should wait for the specific filed from that transaction
  ): Promise<TransactionReceipt> {
    let timerId: NodeJS.Timer;

    return new Promise((res, rej) => {
      timerId = setInterval(async () => {
        const receipt = await web3.getTransactionReceipt(hash);

        if (receipt && (!field || receipt[field])) {
          clearInterval(timerId);
          if (receipt.status) {
            res(receipt);
          } else {
            rej(new Error(CONTRACT_RECEIPT_ERROR(hash)));
          }
        }
      }, 2000);
    });
  },
};

export default ContractApi;
