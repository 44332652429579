import { ethers } from 'ethers';

// instances
import OnboardProvider from './onboard-provider';

// models
import ProvidersBunch from '@/shared/models/connection/providers-bunch';
import DefaultProviders from '@/shared/models/connection/default-providers';

// constants
import RPC_DATA from '@/shared/constants/rpc-data';

function generateProvidersBunch(): ProvidersBunch {
  const entries = Object.entries(RPC_DATA);

  const defaultProviders: DefaultProviders = entries.reduce((prevRpc, [chainId, value]) => {
    const updatedRpc = { ...prevRpc };
    updatedRpc[chainId] = value.wssConnection
      ? new ethers.providers.WebSocketProvider(value.wssConnection)
      : new ethers.providers.JsonRpcProvider(value.httpConnection);
    return updatedRpc;
  }, {} as DefaultProviders);

  return {
    userProvider: new OnboardProvider(),
    defaultProviders,
  };
}

export default generateProvidersBunch();
