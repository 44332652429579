/* eslint-disable */
import { defineStore } from 'pinia';
import { ref } from 'vue';

// api
import CommonApi from '@/api/common.api';

// models
import SearchData from '@/shared/models/common/search-data';

const useCommonStore = defineStore('common', () => {
  // refs
  const ethToUsdt = ref<number>(0);

  // async helpers
  async function searchData(searchString: string): Promise<SearchData> {
    return CommonApi.searchData(searchString);
  }

  async function getEthToUsdt(): Promise<void> {
    try {
      const resp = await CommonApi.getEthToUsdt();
      ethToUsdt.value = resp.usdPrice;
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  return {
    ethToUsdt,
    searchData,
    getEthToUsdt,
  };
});

export default useCommonStore;
