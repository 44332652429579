import { storeToRefs } from 'pinia';

// stores
import useGlobalLoaderStore from '@/stores/global-loader';
import useAuthStore from '@/stores/auth';

// models
import RouterNames from '@/shared/models/common/router-names-types.enum';

export default function checkCreator(
  res: (data: boolean | { name: string }) => void,
  id: NodeJS.Timer | null = null,
): boolean {
  // common
  const authStore = useAuthStore();
  const globalLoaderStore = useGlobalLoaderStore();

  // refs
  const { isGlobalLoading } = storeToRefs(globalLoaderStore);
  const { isCreator } = storeToRefs(authStore);

  if (!isGlobalLoading.value.length) {
    if (id) {
      clearInterval(id);
    }

    if (isCreator.value) {
      res(true);
    } else {
      res({ name: RouterNames.Home });
    }

    return true;
  }

  return false;
}
