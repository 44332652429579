// models
import RpcData from '../models/connection/rpc-data';

// helpers
import getRandomWssOrHttpInfura from '@/shared/helpers/get-random-wss-or-http-infura';

const RPC_DATA: RpcData = {
  [process.env.VUE_APP_NETWORK_CHAIN_ID as string]: {
    name: process.env.VUE_APP_NETWORK_CHAIN_NAME as string,
    token: process.env.VUE_APP_NETWORK_CURRENCY as string,
    // wssConnection: process.env.VUE_APP_WSS_INFURA as string,
    // httpConnection: process.env.VUE_APP_HTTP_INFURA as string,
    // wssConnection: getRandomWssOrHttpInfura(process.env.VUE_APP_WSS_INFURA as string),
    httpConnection: getRandomWssOrHttpInfura(process.env.VUE_APP_HTTP_INFURA as string),
  },
  [process.env.VUE_APP_NETWORK_CHAIN_ID_SECOND as string]: {
    name: process.env.VUE_APP_NETWORK_CHAIN_NAME_SECOND as string,
    token: process.env.VUE_APP_NETWORK_CURRENCY_SECOND as string,
    // wssConnection: getRandomWssOrHttpInfura(process.env.VUE_APP_WSS_INFURA_SECOND as string),
    httpConnection: getRandomWssOrHttpInfura(process.env.VUE_APP_HTTP_INFURA_SECOND as string),
  },
};

export default RPC_DATA;
