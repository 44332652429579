// instances
import http from '@/instances/http';

// helpers
import toFormData from '@/shared/helpers/to-form-data';

// models
import CurrentUserInfo from '@/shared/models/users/current-user-info';
import User from '@/shared/models/users/user';
import PaginationOptionsRequest from '@/shared/models/common/list/pagination-options-request';
import ListWithTotalResponse from '@/shared/models/common/list/list-with-total-response';
import DropItem from '@/shared/models/drops/drop-item';
import Audience from '@/shared/models/users/audience';
import TokenItem from '@/shared/models/drops/token-item';
import FeatureCreator from '@/shared/models/users/feature-creator';
import UpdateUserInfoReq from '@/shared/models/users/update-user-info-req';
import SocialMediaReq from '@/shared/models/users/social-media-req';
import CreatorListFilters from '@/shared/models/users/creator-list-filters';
import DropCreators from '@/shared/models/drops/drop-creators';
import DropCreatorsFilters from '@/shared/models/drops/drop-creators-filters';

const UserApi = {
  // user personal info requests
  async getUserInfo(token: string): Promise<CurrentUserInfo> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const resp = await http.get('/users/current/profile', { headers });
    return resp.data;
  },
  async getUserById(userId: string): Promise<User> {
    const resp = await http.get(`/users/${userId}`);
    return resp.data;
  },
  async getAllCreatorsList(pagination: PaginationOptionsRequest, filters?: CreatorListFilters): Promise<ListWithTotalResponse<FeatureCreator>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/users/drop-creators', { params });

    return resp.data;
  },
  async getUsersFeaturedList(pagination: PaginationOptionsRequest, filters?: CreatorListFilters): Promise<ListWithTotalResponse<FeatureCreator>> {
    const params = { ...pagination, ...filters };

    const resp = await http.get('/users/featured', { params });

    return resp.data;
  },
  async getUserDropListById(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination };

    const resp = await http.get(`/users/${userId}/drops`, { params });
    return resp.data;
  },
  async getUserFeaturingDropListById(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination };

    const resp = await http.get(`/users/${userId}/featuring-drops`, { params });
    return resp.data;
  },
  async getUserCollectedItemsListById(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<TokenItem>> {
    const params = { ...pagination };

    const resp = await http.get(`/users/${userId}/collected-items`, { params });
    return resp.data;
  },
  async getUserCollectedDropsListById(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<DropItem>> {
    const params = { ...pagination };

    const resp = await http.get(`/users/${userId}/collected-drops`, { params });
    return resp.data;
  },
  async getUserAudienceById(userId: string, pagination: PaginationOptionsRequest): Promise<ListWithTotalResponse<Audience>> {
    const params = { ...pagination };

    const resp = await http.get(`/users/${userId}/audience`, { params });
    return resp.data;
  },
  async updateFeaturedUsers(featuredUsers: string[], token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.post('/users/featured', featuredUsers, { headers });
  },
  async updatedUserInfo(updatedUserInfo: UpdateUserInfoReq, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('/users', updatedUserInfo, { headers });
  },
  async updateProfileImage(updatedImage: File | null, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('/users/current/profile-image', toFormData({ File: updatedImage }), { headers });
  },
  async updateBannerImage(updatedImage: File | null, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('/users/current/banner-image', toFormData({ File: updatedImage }), { headers });
  },
  async connectTwitter(twitterInfo: SocialMediaReq, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('users/current/profile/twitter-profile', twitterInfo, { headers });
  },
  async connectInstagram(instagramInfo: SocialMediaReq, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('users/current/profile/instagram-profile', instagramInfo, { headers });
  },
  async connectDiscord(instagramInfo: SocialMediaReq, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('users/current/profile/discord-profile', instagramInfo, { headers });
  },
  async connectYoutube(accessToken: string, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.put('users/current/profile/youtube-profile', { accessToken }, { headers });
  },
  async removeTwitter(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.delete('users/current/profile/twitter-profile', { headers });
  },
  async removeInstagram(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.delete('users/current/profile/instagram-profile', { headers });
  },
  async removeDiscord(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.delete('users/current/profile/discord-profile', { headers });
  },
  async removeYoutube(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.delete('users/current/profile/youtube-profile', { headers });
  },
  async getDropCreatorAdminDetails(
    sharedDeployDropId: string | number,
    pagination: PaginationOptionsRequest,
    filters: DropCreatorsFilters,
    token: string,
  ): Promise<ListWithTotalResponse<DropCreators>> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const params = { sharedDeployDropId, ...pagination, ...filters };

    const resp = await http.get('users/drop-creators/admin-details', { headers, params });

    return resp.data;
  },
};

export default UserApi;
