/* eslint-disable */
import { Chain } from '@web3-onboard/common';
import Onboard, { OnboardAPI } from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import coinbaseWalletModule from '@web3-onboard/coinbase';
import walletConnectModule from '@web3-onboard/walletconnect';
import ledgerModule from '@web3-onboard/ledger';
import trezorModule from '@web3-onboard/trezor';
import { ethers } from 'ethers';

// helpers
import convertNumberToHexadecimal from '@/shared/helpers/convert-number-to-hexadecimal';

// models
import ProviderWrapper from '@/shared/models/connection/provider-wrapper';
import ConnectedUserInfo from '@/shared/models/connection/connected-user-info';

// melonLogo
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import melonLogo from '@/assets/images/icons/melon.svg';

// helpers
import removeCoinBaseConnectionFromLocalStorage
  from '@/shared/helpers/remove-coin-base-connection-from-local-storage';

// constants
import {
  ADDRESS_CHECKSUM_ERROR,
  USER_NOT_AUTHORIZED_ERROR,
  WALLET_CONNECTION_CANCELED,
} from '@/shared/constants/messages';
import RPC_DATA from '@/shared/constants/rpc-data';

class OnboardProvider extends ProviderWrapper {
  public provider: OnboardAPI;

  constructor() {
    super();
    // define chains
    const entries = Object.entries(RPC_DATA);
    const chains: Chain[] = entries.map(([id, data]) => ({
      id: `0x${convertNumberToHexadecimal(id)}`,
      token: data.token,
      label: data.name,
      rpcUrl: data.httpConnection,
    }));

    // define modules
    const injected = injectedModule();
    const coinbaseWalletSdk = coinbaseWalletModule();
    // const ledger = ledgerModule({
    //   projectId: 'test',
    //   walletConnectVersion: 2,
    // });
    const trezor = trezorModule({
      email: 'tom@melon.ooo',
      appUrl: 'https://app.melon.ooo/',
    });
    const walletConnect = walletConnectModule({
      projectId: process.env.VUE_APP_WALLET_CONNECT_ID as string,
      requiredChains: chains.map((chain) => parseInt(chain.id, 16)),
      dappUrl: window.location.origin,
    });

    this.provider = Onboard({
      chains,
      wallets: [injected, coinbaseWalletSdk, walletConnect, trezor],
      // wallets: [injected, coinbaseWalletSdk, walletConnect, ledger, trezor],
      appMetadata: {
        name: 'Melon',
        icon: melonLogo,
        description: 'description',
        agreement: {
          version: process.env.VUE_APP_VERSION as string,
          termsUrl: `${window.location.origin}/terms-of-service`,
          privacyUrl: `${window.location.origin}/privacy-policy`,
        },
      },
      connect: { showSidebar: true },
      accountCenter: {
        desktop: { enabled: false },
        mobile: { enabled: false },
      },
    });
  }

  // eslint-disable-next-line
  getWeb3Provider(type: string): any {
    const provider = this.provider.state.get().wallets.find((w) => w.label === type)?.provider;

    if (!provider) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    return provider;
  }

  getNearestWalletData(): ConnectedUserInfo | null {
    const wallet = this.provider.state.get().wallets[0];
    let address = wallet?.accounts[0]?.address;

    if (address) {
      try {
        address = ethers.utils.getAddress(address);
      } catch (e) {
        throw new Error(ADDRESS_CHECKSUM_ERROR);
      }

      return { address, type: wallet.label };
    }

    return null;
  }

  async connect(): Promise<ConnectedUserInfo> {
    const wallet = (await this.provider.connectWallet())[0];
    const address = wallet?.accounts[0]?.address;

    if (!address) {
      throw new Error(WALLET_CONNECTION_CANCELED);
    }

    try {
      return {
        address: ethers.utils.getAddress(address),
        type: wallet.label,
      };
    } catch (e) {
      throw new Error(ADDRESS_CHECKSUM_ERROR);
    }
  }

  async autoConnect(type: string): Promise<void> {
    await this.provider.connectWallet({ autoSelect: { label: type, disableModals: true } });
  }

  async disconnect(exceptType?: string): Promise<void> {
    const allWallets = this.provider.state.get().wallets;
    for (const wallet of allWallets) {
      if (wallet.label !== exceptType) {
        await this.provider.disconnectWallet({ label: wallet.label });

        if (wallet.label.toLowerCase() === 'coinbase wallet') {
          removeCoinBaseConnectionFromLocalStorage();
        }
      }
    }
  }

  getChainId(type: string): string {
    const chainData = this.provider.state.get().wallets.find((w) => w.label === type)?.chains[0];

    if (!chainData) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    return parseInt(chainData.id, 16).toString();
  }

  checkIsCorrectChain(type: string): boolean {
    return Object.keys(RPC_DATA).includes(this.getChainId(type));
  }

  getChainCurrency(type: string): string | null {
    const id = this.getChainId(type);
    const wrapperState = this.provider.state.get();
    return wrapperState.chains.find((chain) => chain.id === id)?.token || null;
  }

  checkConnection(type?: string, address?: string): boolean {
    const { wallets } = this.provider.state.get();

    if (!type) {
      return !!wallets.length;
    }

    const wallet = wallets.find((w) => w.label === type);

    if (!address) {
      return !!wallet;
    }

    try {
      address = ethers.utils.getAddress(address);

      return !!wallet?.accounts.find((a) => ethers.utils.getAddress(a.address) === address);
    } catch (e) {
      throw new Error(ADDRESS_CHECKSUM_ERROR);
    }
  }
}

export default OnboardProvider;
