import {
  onMounted,
  onUnmounted,
  ref,
  type Ref,
} from 'vue';

// models
import WindowSize from '../models/common/window-size';

function useWindowResize(): Ref<WindowSize> {
  // refs
  const sizes = ref<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // helpers
  function resize() {
    sizes.value = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  // lifecycle hooks
  onMounted(() => {
    resize();
    window.addEventListener('resize', resize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resize);
  });

  return sizes;
}

export default useWindowResize;
