enum RouterNames {
  Home = 'home',
  Drop = 'drop',
  DropComingSoon = 'drop-coming-soon',
  OldDrop = 'upcoming',
  MelonPass = 'melon-pass',
  Token = 'token',
  TermsOfService = 'terms-of-service',
  PrivacyPolicy = 'privacy-policy',
  ProfileContent = 'profile-content',
  ProfileAudience = 'profile-audience',
  ProfileDrops = 'profile-drops',
  ProfileFeaturingDrops = 'profile-featuring-drops',
  ProfileCollectedDrops = 'profile-collected-drops',
  ProfileSettingsEditProfile = 'profile-settings-edit-profile',
  ProfileSettingYourDrops = 'profile-settings-your-drops',
  ProfileSettingDropDetails = 'profile-settings-drop-details',
  SuccessTwitterConnection = 'success-twitter-connection',
  SuccessDiscordConnection = 'success-discord-connection',
  SuccessYoutubeConnection = 'success-youtube-connection',
  AdminDashboardMainDrop = 'admin-dashboard-main-drop',
  AdminDashboardFeaturedCreators = 'admin-dashboard-featured-creators',
  AdminDashboardFeaturedDrops = 'admin-dashboard-featured-drops',
  AdminDashboardUsersSettings = 'admin-dashboard-users-settings',
  AdminDashboardUserUpsert = 'admin-dashboard-user-upsert',
  AdminDashboardListOfDrops = 'admin-dashboard-list-of-drops',
  DropCreationContentType = 'drop-creation-content-type',
  DropCreationReleaseInfo = 'drop-creation-release-info',
  DropCreationSaleConfiguration = 'drop-creation-sale-configuration',
  DropCreationCollaboratorsAndSplits = 'drop-creation-collaborators-and-splits',
  DropCreationReviewAndDeploy = 'drop-creation-review-and-deploy',
  DropListUpcomingDrops = 'drop-list-upcoming-drops',
  DropListFeaturedDrops = 'drop-list-featured-drops',
  DropListLiveDrops = 'drop-list-live-drops',
  DropListRecentDrops = 'drop-list-recent-drops',
  DropListFeaturedCreators = 'drop-list-featured-creators',
  DropListAllCreators = 'drop-list-all-creators',
  DropListAllDrops = 'drop-list-all-drops',
}

export default RouterNames;
