/* eslint-disable */
import { SplitV2Client } from '@0xsplits/splits-sdk';
import { ethers } from 'ethers';
import {
  createPublicClient,
  createWalletClient,
  custom,
  http,
} from 'viem';
import { privateKeyToAccount } from 'viem/accounts';
import { mainnet, polygonMumbai, sepolia } from 'viem/chains';

import ChainTypes from '@/shared/models/common/chain-types.enum';

export function getSplitClient(chainId: number, address: any, provider: any) {
  // const walletClient = createWalletClient({
  //   account: privateKeyToAccount('0x6a1c050b41bd01ffd2d9548b30a97e7648c3613ad9a47598e688a19928c83663'),
  //   chain: polygonMumbai,
  //   // transport: custom(provider.userProvider.provider.state.select()),
  //   transport: http(),
  // });

  console.log(provider);

  const walletClient = createWalletClient({
    account: address,
    chain: sepolia,
    transport: custom(provider.provider),
    // transport: http('https://polygon-mumbai.g.alchemy.com/v2/uDlskVFthqfNDNyXEi60DsF1xAX771-0'),
  });

  console.log(walletClient);

  const publicClient = createPublicClient({
    batch: {
      multicall: true,
    },
    chain: sepolia,
    transport: custom(provider.provider),
  });

  console.log(publicClient);

  return new SplitV2Client({
    chainId,
    publicClient,
    walletClient,
    // publicClient, // viem public client (optional, required if using any of the contract functions)
    // walletClient, // viem wallet client (optional, required if using any contract write functions. must have an account already attached)
    // includeEnsNames, // boolean, defaults to false. If true, will return ens names for any split recipient or controller (only for mainnet)
    // // If you want to return ens names on chains other than mainnet, you can pass in a mainnet public client
    // // here. Be aware though that the ens name may not necessarily resolve to the proper address on the
    // // other chain for non EOAs (e.g. Gnosis Safe's)
    // ensPublicClient, // viem public client (optional)
    apiConfig: {
      apiKey: 'a99bf7271ec2e34499e25558' // You can create an API key by signing up on our app, and accessing your account settings at app.splits.org/settings.
    },
  });
}
