import { storeToRefs } from 'pinia';

// store
// import useAuthStore from '@/stores/auth';
import useGlobalLoaderStore from '@/stores/global-loader';

// models
// import RouterNames from '@/shared/models/common/router-names-types.enum';
import { useRoute } from 'vue-router';
import useAdminDropStore from '@/stores/admin-drop';
import DropStatuses from '@/shared/models/drops/drop-statuses.enum';
// import RouterNames from '@/shared/models/common/router-names-types.enum';

export default async function checkDropPublished(
  dropId: string,
  id: NodeJS.Timer | null = null,
) {
  console.log('allo');
  // common
  // const authStore = useAuthStore();
  const globalLoaderStore = useGlobalLoaderStore();
  const adminDropStore = useAdminDropStore();

  const route = useRoute();
  // const router = useRouter();

  // refs
  // const { isAuthorized } = storeToRefs(authStore);
  const { isGlobalLoading } = storeToRefs(globalLoaderStore);

  if (!isGlobalLoading.value.length) {
    if (id) {
      clearInterval(id);
    }

    console.log(route);

    if (dropId) {
      const data = await adminDropStore.getDropDataById(dropId as string);
      console.log(data, '123123123123');
      if (data.status !== DropStatuses.Created && data.status !== DropStatuses.SentToPublish) {
        return false;
      }
      return true;
    }
    return true;
  }

  return false;
}
