/* eslint-disable */
import { ethers } from 'ethers';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { defineStore } from 'pinia';
// instances
import Providers from '@/instances/providers';

import http from '@/instances/http';
// api
import AuthApi from '@/api/auth.api';
import ContractApi from '@/api/contract.api';
import UserApi from '@/api/user.api';

// services
import LocalStorageService from '@/services/local-storage.service';

// store
import useGlobalLoaderStore from '@/stores/global-loader';

// helpers
import calcTimeDiff from '@/shared/helpers/calc-time-diff';
import parseTokenBundle from '@/shared/helpers/parse-token-bundle';

// models
import TokenBundleWithExp from '@/shared/models/connection/token-bundle-with-exp';
import ConnectionData from '@/shared/models/connection/connection-data';
import TokenBundle from '@/shared/models/connection/token-bundle';
import Session from '@/shared/models/connection/session';
import CurrentUserInfo from '@/shared/models/users/current-user-info';
import RouterNames from '@/shared/models/common/router-names-types.enum';
import SystemError from '@/shared/models/common/system-error';
import UserRoleTypes from '@/shared/models/users/user-role-types.enum';

// constants
import {
  NONCE_MESSAGE,
  SIGNING_AUTH_CANCELED,
  TOKEN_EXPIRED_ERROR,
  USER_NOT_AUTHORIZED_ERROR,
} from '@/shared/constants/messages';

// personal constants
const initialSession: Session = {
  token: null,
  refreshToken: null,
  expDate: null,
  refreshTimerId: null,
};

const initialConnectionData: ConnectionData = {
  walletAddress: null,
  connectionType: null,
  isMainNet: null,
  chainId: null,
  chainCurrency: null,
};

const initialUser: CurrentUserInfo = {
  publicAddress: '',
  nickname: '',
  profileImage: null,
  profileBanner: null,
  balance: null,
  isVerified: false,
  isOwnProfile: false,
  email: null,
  type: null,
};

const MINUTES_5 = 5 * 60 * 1000;
const REFRESH_AVAILABLE = false;
const AUTH_ON_REFRESH_ERROR = false;

const useAuthStore = defineStore('auth', () => {
  // common
  const globalLoaderStore = useGlobalLoaderStore();
  let timerCostylId: NodeJS.Timer | null = null;
  const route = useRoute();

  // refs
  const session = ref<Session>({ ...initialSession });
  const connectionData = ref<ConnectionData>({ ...initialConnectionData });
  const user = ref<CurrentUserInfo>({ ...initialUser });
  const systemError = ref<SystemError>({
    title: null,
    description: null,
    asHtml: false,
  });

  // computed
  const isAuthorized = computed<boolean>(() => (!!session.value.token && !!user.value.publicAddress));
  const isAdmin = computed(() => (isAuthorized.value && user.value.type === UserRoleTypes.ADMIN));
  const isCreator = computed(() => (isAuthorized.value && user.value.type === UserRoleTypes.CREATOR));

  // helpers
  function clearPoweredByFromOnboardModalByInterval(): void {
    timerCostylId = setInterval(() => {
      const el = document?.querySelector(':root body onboard-v2')?.shadowRoot?.querySelector('.sidebar > div > svg');
      if (el) {
        el.setAttribute('style', 'display: none;');

        clearIntervalCostyl();
      }
    }, 40);
  }
  function hideOnboardModalByInterval(): void {
    timerCostylId = setInterval(() => {
      const el = document?.querySelector(':root body onboard-v2')?.shadowRoot?.querySelector('.svelte-baitaa');

      if (el) {
        el.setAttribute('style', 'display: none');
        clearIntervalCostyl();
      }
    }, 1);
  }

  function clearIntervalCostyl(): void {
    if (timerCostylId) {
      clearInterval(timerCostylId);
      timerCostylId = null;
    }
  }

  function closeSystemErrorModal(): void {
    systemError.value = {
      title: null,
      description: null,
      asHtml: false,
    };
  }

  function setSystemError({ title, description, asHtml = false }: SystemError): void {
    systemError.value = {
      title,
      description,
      asHtml,
    };
  }

  function checkSession(): void {
    if (!REFRESH_AVAILABLE) {
      http.interceptors.response.use((response) => {
        return response;
      }, async (error) => {
        const originalRequest: any = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          logout();
          if (
            route.name === RouterNames.SuccessDiscordConnection
            || route.name === RouterNames.SuccessTwitterConnection
            || route.name === RouterNames.SuccessYoutubeConnection
          ) {
            window.close();
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      });
    }
  }

  function getConnectionData(walletAddress: string, connectionType: string): ConnectionData {
    const currentProvider = Providers.userProvider;
    if (currentProvider.checkConnection(connectionType, walletAddress)) {
      return {
        walletAddress,
        connectionType,
        isMainNet: currentProvider.checkIsCorrectChain(connectionType),
        chainId: currentProvider.getChainId(connectionType),
        chainCurrency: currentProvider.getChainCurrency(connectionType),
      };
    }

    return { ...initialConnectionData };
  }

  function setUserAuth(address: string, type: string, tokenBundle: TokenBundleWithExp): void {
    connectionData.value = getConnectionData(address, type);
    session.value = { ...tokenBundle, refreshTimerId: session.value.refreshTimerId };

    LocalStorageService.setSession(address, { ...tokenBundle, type });
  }

  function clearRefreshId(): void {
    const { refreshTimerId } = session.value;

    if (refreshTimerId !== null) {
      clearTimeout(refreshTimerId);
      session.value.refreshTimerId = null;
    }
  }

  // async helpers
  async function setupApplicationData(): Promise<void> {
    ContractApi.setupContractAddresses();

    try {
      await updateFromLocalStorage();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateFromLocalStorage(providedAddress?: string): Promise<void> {
    const address = providedAddress || LocalStorageService.getLastUser();
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();
    let withWeb3Disconnect = false;

    try {
      const localstorageData = LocalStorageService.getSession(address);

      if (!(localstorageData && address)) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      const { type, ...tokenBundle } = localstorageData;
      let updatedTokenBundle: TokenBundleWithExp | null = null;

      if (!Providers.userProvider.checkConnection(type)) {
        await new Promise((res, reject) => {
          let errorTimeoutId = setTimeout(() => {
            withWeb3Disconnect = true;
            reject(USER_NOT_AUTHORIZED_ERROR);
          }, 10_000);

          let connectTimeoutId: NodeJS.Timeout | null = setTimeout(() => {
            hideOnboardModalByInterval();
            Providers.userProvider.connect().then(() => {
              if (errorTimeoutId) {
                clearTimeout(errorTimeoutId);
                errorTimeoutId = null;
              }

              clearIntervalCostyl();

              res(true);
            });
          }, 3000);

          Providers.userProvider.autoConnect(type).then(() => {
            if (connectTimeoutId) {
              clearTimeout(connectTimeoutId);
              connectTimeoutId = null;
            }

            if (errorTimeoutId) {
              clearTimeout(errorTimeoutId);
              errorTimeoutId = null;
            }

            res(true);
          });
        });
      }

      if (!Providers.userProvider.checkConnection(type, address)) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      const isTokenExpired = (calcTimeDiff(tokenBundle.expDate as string) - MINUTES_5) <= 0;

      if (isTokenExpired) {
        updatedTokenBundle = await reqUserAuth(address, type);
      }

      setUserAuth(address, type, isTokenExpired && updatedTokenBundle ? updatedTokenBundle : tokenBundle);
      await loadUserData();
      waitTokenExpiration();

      // if (isTokenExpired) {
      //   await reqRefreshToken(address, type, tokenBundle);
      // } else {
      //   setUserAuth(address, type, tokenBundle);
      //   await loadUserData();
      //   waitTokenExpiration();
      // }
    } catch (e) {
      await logout(address, withWeb3Disconnect);
      throw e;
    } finally {
      clearGlobalLoader();
    }
  }

  async function reqUserAuth(address: string, connectionType: string): Promise<TokenBundleWithExp> {
    const nonce = await AuthApi.getNonce(address);

    let signature: string;
    const provider = Providers.userProvider.getWeb3Provider(connectionType);

    try {
      const tempSigner = new ethers.providers.Web3Provider(provider).getSigner();
      signature = await tempSigner.signMessage(NONCE_MESSAGE(nonce));
    } catch (e) {
      throw new Error(SIGNING_AUTH_CANCELED);
    }

    const rawTokenBundle = await AuthApi.authUser(address, signature);
    return parseTokenBundle(rawTokenBundle);
  }

  async function login(): Promise<void> {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();
    try {
      await logout();
      // timer costyl start
      clearPoweredByFromOnboardModalByInterval();

      const userInfo = await Providers.userProvider.connect();

      clearIntervalCostyl();

      // timer costyl end

      const canUpdateFromLocalStorage = LocalStorageService.getSession(userInfo.address);

      if (canUpdateFromLocalStorage) {
        await updateFromLocalStorage(userInfo.address);
      } else {
        try {
          const tokenBundle = await reqUserAuth(userInfo.address, userInfo.type);
          setUserAuth(userInfo.address, userInfo.type, tokenBundle);
          await loadUserData();
          waitTokenExpiration();
        } catch (e) {
          await Providers.userProvider.disconnect();
          throw e;
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      clearGlobalLoader();
    }
  }

  async function handleWalletUpdate(): Promise<void> {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();
    try {
      const walletData = Providers.userProvider.getNearestWalletData();

      if (!walletData) {
        await logout();
        return;
      }

      if (walletData.address === connectionData.value.walletAddress) {
        connectionData.value = getConnectionData(walletData.address, walletData.type);
        return;
      }

      const canUpdateFromLocalStorage = LocalStorageService.getSession(walletData.address);

      if (canUpdateFromLocalStorage) {
        await updateFromLocalStorage(walletData.address);
      } else {
        try {
          const tokenBundle = await reqUserAuth(walletData.address, walletData.type);
          setUserAuth(walletData.address, walletData.type, tokenBundle);
          await loadUserData();
          waitTokenExpiration();
        } catch (e) {
          await logout();
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      clearGlobalLoader();
    }
  }

  async function reqRefreshToken(address: string, type: string, tokenBundle: TokenBundle, selfCall = false): Promise<void> {
    try {
      if (!tokenBundle.refreshToken || !REFRESH_AVAILABLE) {
        throw new Error(TOKEN_EXPIRED_ERROR);
      }

      try {
        const rawTokenBundle = await AuthApi.refreshToken(tokenBundle.refreshToken as string);
        const newTokenBundle = parseTokenBundle(rawTokenBundle);

        setUserAuth(address, type, newTokenBundle);
        await loadUserData();
      } catch (e) {
        if (AUTH_ON_REFRESH_ERROR && Providers.userProvider.checkConnection(type, address)) {
          const newTokenBundle = await reqUserAuth(address, type);
          setUserAuth(address, type, newTokenBundle);
          await loadUserData();
        } else {
          throw e;
        }
      }

      waitTokenExpiration();
    } catch (e) {
      if (selfCall) {
        console.error(e);
        await logout(address);
      }

      throw e;
    }
  }

  async function waitTokenExpiration(): Promise<void> {
    const { expDate } = session.value;

    clearRefreshId();

    if (expDate && REFRESH_AVAILABLE) {
      const diff = calcTimeDiff(expDate) - MINUTES_5;

      if (diff > 0) {
        const timerId = setTimeout(
          () => reqRefreshToken(
            connectionData.value.walletAddress as string,
            connectionData.value.connectionType as string,
            session.value,
            true,
          ),
          diff,
        );
        // eslint-disable-next-line
        session.value.refreshTimerId = timerId as any;
      } else {
        await reqRefreshToken(
          connectionData.value.walletAddress as string,
          connectionData.value.connectionType as string,
          session.value,
          true,
        );
      }
    }
  }

  async function logout(providedAddress?: string | null, withDisconnect = true): Promise<void> {
    const address = providedAddress || connectionData.value.walletAddress;

    if (address) {
      LocalStorageService.clearSession(address);

      if (address === connectionData.value.walletAddress) {
        LocalStorageService.clearLastUser();
        clearRefreshId();
        session.value = { ...initialSession };
        connectionData.value = { ...initialConnectionData };
        user.value = { ...initialUser };

        if (withDisconnect) {
          await Providers.userProvider.disconnect();
        }
      }
    }
  }

  // (typical requests)
  async function loadBalance(): Promise<void> {
    const { connectionType, walletAddress } = connectionData.value;

    if (connectionType && walletAddress) {
      const web3 = new ethers.providers.Web3Provider(Providers.userProvider.getWeb3Provider(connectionType));

      try {
        const balance = (await web3.getBalance(walletAddress)).toString();
        user.value = { ...user.value, balance };
      } catch (e) {
        console.error(e);
      }
    } else {
      user.value = { ...user.value, balance: null };
    }
  }

  async function loadUserData(): Promise<void> {
    const { token } = session.value;

    if (token) {
      try {
        const userData = await UserApi.getUserInfo(token);
        user.value = {
          ...userData,
          balance: user.value.balance,
        };
      } catch (e) {
        console.error(e);
      }
    } else {
      user.value = {
        ...initialUser,
        balance: user.value.balance,
      };
    }
  }

  // watchers
  watch(connectionData, loadBalance);

  return {
    session,
    connectionData,
    user,
    systemError,
    isAuthorized,
    isAdmin,
    isCreator,
    setupApplicationData,
    logout,
    login,
    closeSystemErrorModal,
    setSystemError,
    loadUserData,
    checkSession,
    handleWalletUpdate,
  };
});

export default useAuthStore;
