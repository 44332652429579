/* eslint-disable */
import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

// apis
import AdminDropApi from '@/api/admin-drop';
import DropApi from '@/api/drop.api';

// stores
import useAuthStore from '@/stores/auth';
import useGlobalLoaderStore from '@/stores/global-loader';

// constants
import { USER_NOT_AUTHORIZED_ERROR } from '@/shared/constants/messages';

// models
import SetMainDropData from '@/shared/models/drops/set-main-drop-data';
import ListWithTotalResponse from '@/shared/models/common/list/list-with-total-response';
import DraftDropItem from '@/shared/models/drops/draft-drop-item';
import PaginationOptionsRequest from '@/shared/models/common/list/pagination-options-request';
import FiltersDropItems from '@/shared/models/admin/filters-drop-items';
import FiltersDraftDropItems from '@/shared/models/admin/filters-draft-drop-items';
import DropItem from '@/shared/models/drops/drop-item';
import DropItemAdminDetails from '@/shared/models/drops/drop-item-admin-details';

import generateContracts from '@/instances/contracts';
import Creator from '@/shared/models/users/creator';
import AirdropContractApi from '@/api/airdrop-contract.api';
import RecepientsType from '@/shared/models/airdrop/recepients-type';
import CreatorWithRoyalty from '@/shared/models/drops/creator-with-royalty';
import DropCollaboratorsAndSplits from '@/shared/models/drops/drop-collaborators-and-splits';
import { ethers } from 'ethers';
import Providers from '@/instances/providers';
import DropCreatingContractFinalData from '@/shared/models/airdrop/final-data.type';

const useAdminDropStore = defineStore('admin-drop', () => {
  // common
  const authStore = useAuthStore();
  const globalLoaderStore = useGlobalLoaderStore();

  // refs
  const { session, connectionData } = storeToRefs(authStore);

  const featuredDrops = ref<ListWithTotalResponse<DropItem>>({ data: [], total: 0 });

  // async helpers
  async function removeMainDrop(): Promise<void> {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();

    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      await AdminDropApi.removeMainDrop(session.value.token);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.response.data || e.message || e,
      });
      throw new Error(e.message || e);
    } finally {
      clearGlobalLoader();
    }
  }

  async function getFeaturedDropList(
    filters: FiltersDropItems | null,
    pagination?: PaginationOptionsRequest,
  ): Promise<ListWithTotalResponse<DropItem>> {
    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      return await AdminDropApi.getFeaturedDropList(
        filters,
        pagination || {
          pageSize: 20,
          offset: 0,
        },
        session.value.token,
      );
    } catch (e: any) {
      throw new Error(e.message || e);
    }
  }

  async function setMainDrop(data: SetMainDropData): Promise<void> {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();

    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      await AdminDropApi.setMainDrop(data, session.value.token);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.response.data || e.message || e,
      });
      throw new Error(e.message || e);
    } finally {
      clearGlobalLoader();
    }
  }

  async function getPublishedDrops(
    pagination: PaginationOptionsRequest,
    filters: FiltersDropItems | null,
  ): Promise<ListWithTotalResponse<DropItem>> {
    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      return await AdminDropApi.getPublishedDrops(pagination, filters, session.value.token);
    } catch (e: any) {
      throw new Error(e.message || e);
    }
  }

  async function getDropDataById(dropId: string | number): Promise<DropItemAdminDetails> {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();

    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      return await AdminDropApi.getDropAdminDetailsById(dropId, session.value.token);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e?.response?.message || e?.message || e,
      });

      throw new Error(e?.response?.message || e?.message || e);
    } finally {
      clearGlobalLoader();
    }
  }

  async function getDraftDrops(
    pagination: PaginationOptionsRequest,
    filters: FiltersDraftDropItems,
  ): Promise<ListWithTotalResponse<DraftDropItem>> {
    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      return await AdminDropApi.getDraftDrops(pagination, filters, session.value.token);
    } catch (e: any) {
      throw new Error(e.message || e);
    }
  }

  async function getDropDeploymentDetails(
    id: string,
  ): Promise<DropCreatingContractFinalData> {
    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      return await AdminDropApi.getDropAdminDetailsForDeployment(id, session.value.token);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Preparing the drop',
        description: 'Drop files are being prepared, please wait a bit and try again later.',
      });
      throw new Error(e.message || e);
    }
  }

  async function createSplit(recepients: DropCollaboratorsAndSplits[]) {
    console.log('allo');
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();

    try {
      console.log('HASJLKDJHA:LKSD');
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      const { chainId, connectionType, walletAddress } = connectionData.value;
      const web3 = new ethers.providers.Web3Provider(Providers.userProvider.getWeb3Provider(connectionType as string));

      const splitData: RecepientsType = {
        recipients: [],
        distributorFeePercent: 1,
        controller: '',
      };

      recepients.forEach((el) => {
        splitData.recipients.push({
          address: el.walletAddress,
          percentAllocation: el.royaltyPercentage,
        });
        if (el.isMain) {
          splitData.controller = el.walletAddress;
        }
      });

      console.log(splitData);

      return await AirdropContractApi.createSplit(Number(chainId), splitData, walletAddress as string, web3);
    } finally {
      clearGlobalLoader();
    }
  }

  async function getSplitInfo(splitAddress: string) {
    const clearGlobalLoader = globalLoaderStore.startGlobalLoading();

    try {
      if (!session.value.token) {
        throw new Error(USER_NOT_AUTHORIZED_ERROR);
      }

      const { chainId, connectionType, walletAddress } = connectionData.value;
      const web3 = new ethers.providers.Web3Provider(Providers.userProvider.getWeb3Provider(connectionType as string));

      return await AirdropContractApi.getSpliInfo(Number(chainId), walletAddress as string, web3, splitAddress);
    } catch (e: any) {
      console.log(e);
      throw new Error(e.message || e);
    } finally {
      clearGlobalLoader();
    }
  }

  async function setSplitRoyaltiesContract(dropId: string, splitContract: string): Promise<void> {
    const clearLoader = globalLoaderStore.startGlobalLoading();

    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    try {
      await AdminDropApi.setSplitRoyaltiesContract(dropId, splitContract, session.value.token);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function getSelectedFeaturedDropList(pagination: PaginationOptionsRequest): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    // const clearLoader = globalLoaderStore.startGlobalLoading();

    try {
      featuredDrops.value = await DropApi.getSelectedFeaturedDropList(pagination);
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      // clearLoader();
    }
  }

  async function removeFeaturedDrops(userId: string): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoaderStore.startGlobalLoading();
    let featuredData: string[] = [];
    featuredDrops.value.data.forEach((el) => {
      if (el.id !== userId) {
        featuredData = [...featuredData, el.id];
      }
    });

    try {
      await AdminDropApi.updateFeaturedDrops(featuredData, session.value.token);
      featuredDrops.value = await DropApi.getSelectedFeaturedDropList({ offset: 0, pageSize: 50 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function addFeaturedDrops(userId: string): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoaderStore.startGlobalLoading();
    let featuredData: string[] = [];
    featuredDrops.value.data.forEach((el) => {
      featuredData = [...featuredData, el.id];
    });
    featuredData.push(userId);

    try {
      await AdminDropApi.updateFeaturedDrops(featuredData, session.value.token);
      featuredDrops.value = await DropApi.getSelectedFeaturedDropList({ offset: 0, pageSize: 50 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  async function updateFeaturedDrops(featuredDropsList: string[]): Promise<void> {
    if (!session.value.token) {
      throw new Error(USER_NOT_AUTHORIZED_ERROR);
    }

    const clearLoader = globalLoaderStore.startGlobalLoading();

    try {
      await AdminDropApi.updateFeaturedDrops(featuredDropsList, session.value.token);
      featuredDrops.value = await DropApi.getSelectedFeaturedDropList({ offset: 0, pageSize: 50 });
    } catch (e: any) {
      authStore.setSystemError({
        title: 'Error',
        description: e.message,
      });
      throw new Error(e.message);
    } finally {
      clearLoader();
    }
  }

  return {
    featuredDrops,
    getSelectedFeaturedDropList,
    removeFeaturedDrops,
    addFeaturedDrops,
    updateFeaturedDrops,
    setMainDrop,
    removeMainDrop,
    getDraftDrops,
    getPublishedDrops,
    getDropDataById,
    getFeaturedDropList,
    getDropDeploymentDetails,
    createSplit,
    getSplitInfo,
    setSplitRoyaltiesContract,
  };
});

export default useAdminDropStore;
